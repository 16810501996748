
import './Contact.css'

import PresentationContact from '../../components/Contact/PresentationContact';
import Formulaire from '../../components/Contact/Formulaire';
import Questions from '../../components/Contact/Questions';

const Contact = () => {

  return (
    <>
      <section className='Contact'>


        <PresentationContact />
        <div className='Container'>
          <Formulaire />
          <Questions />
        </div>
      </section>
    </>
  )
}

export default Contact