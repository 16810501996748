import React from 'react'
import './footer.css'

import { Link } from 'react-router-dom';


import { BsFacebook } from 'react-icons/bs';
import { FaSquareInstagram } from 'react-icons/fa6'
import { FaTiktok } from 'react-icons/fa'
import { GiPositionMarker } from 'react-icons/gi'
import { AiFillCopyrightCircle } from 'react-icons/ai'

const footer = () => {
    return (
        <footer>
            <div className='block1'>
                <h3>
                    Mes réseaux
                </h3>
                <div>
                    <a href="https://www.facebook.com/regine.szwabinski?locale=fr_FR" target="_blank" rel="noopener noreferrer"><BsFacebook className='icone' /></a>
                    <a href="https://www.instagram.com/regineszwabinski?igsh=MTl6dHpyaWg2bzkyaw==" target="_blank" rel="noopener noreferrer"><FaSquareInstagram className='icone-insta' /></a>
                    <a href="https://www.tiktok.com/@regineszwabinski6" target="_blank" rel="noopener noreferrer"><FaTiktok className='icone' /></a>
                </div>

            </div>
            <div className='block2'>
                <h3>
                    Ma localisation
                </h3>
                <div>

                    <div>
                        <div>
                            <GiPositionMarker className='icone-localisation' />
                        </div>
                        <p>
                            15 rue du Montceau
                        </p>
                        <span>
                            62440 HARNES
                        </span>
                    </div>
                </div>
            </div>
            <div className='block3'>
                <h3>
                    Informations
                </h3>
                <Link to="/Mentions_Legales" className='LinkFooter'>Mentions légales</Link>
                <Link to="/JeMeConnecte" className='LinkFooter'>Connexion</Link>

            </div>
            <div className='block4'>
                Copyright Alexis Delecroix <AiFillCopyrightCircle className='icone-copyright' /> 2024
            </div>
        </footer>



    )
}

export default footer