import React, { useState } from "react";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";

const Update = ({ onClose }) => {
    const [articleId, setArticleId] = useState("");
    const [articleData, setArticleData] = useState({
        titre_Articles: "",
        texte_Articles: "",
        texte2_Articles: "",
        texte3_Articles: "",
        date_Articles: "",
        auteur_Articles: "",
        image_Articles: "",
    });

    const [showForm, setShowForm] = useState(false);
    const [newImage, setNewImage] = useState(null);

    const handleGetArticleClick = async () => {
        try {
            const response = await axios.get(
                `/app/getArticleById/${articleId}`
            );

            if (response.data.articleData) {
                setArticleData(response.data.articleData);
                setShowForm(true);
            } else {
                console.log("Aucune donnée d'article reçue");
            }
        } catch (error) {
            console.error(
                "Erreur lors de la récupération des données de l'article pour la modification",
                error
            );
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!articleId) {
            console.error("ID de l'article manquant");
            return;
        }
    
        try {
            // FormData pour gérer l'envoi d'images
            const formData = new FormData();
    
            // Vérifier si une nouvelle image a été sélectionnée
            if (newImage) {
                formData.append("image_Articles", newImage); // Ajout de la nouvelle image
    
                // Envoi des données de l'article avec le nom de la nouvelle image
                console.log('FormData:', formData);
                const responseImage = await axios.post(
                    "/app/upload",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                
                if (responseImage.data.imageName) {
                    console.log('Image Upload Response:', responseImage.data);
                
                    // Récupération du nom de la nouvelle image
                    const newImageName = responseImage.data.imageName;
                
                    // Envoi des données de l'article avec le nom de la nouvelle image
                    const responseArticle = await axios.put(
                        `/app/update/${articleId}`,
                        {
                            titre_Articles: articleData.titre_Articles,
                            texte_Articles: articleData.texte_Articles,
                            texte2_Articles: articleData.texte2_Articles,
                            texte3_Articles: articleData.texte3_Articles,
                            date_Articles: articleData.date_Articles,
                            auteur_Articles: articleData.auteur_Articles,
                            image_Articles: newImageName
                        }
                    );
                
                    if (responseArticle.data.articleData) {
                        console.log(responseArticle.data.message);
                        console.log('Updated Article Data:', responseArticle.data.articleData);
                
                    } else {
                        console.log("Aucune donnée d'article mise à jour reçue");
                    }
                    onClose();
                    // window.location.reload();
                } else {
                    console.error('Erreur lors du téléchargement de la nouvelle image');
                    // Gérer l'erreur ou informer l'utilisateur
                }
                onClose();
                // window.location.reload();
            } else {
                // Si aucune nouvelle image n'est sélectionnée, soumettre simplement les données de l'article sans téléchargement d'image
                const responseArticle = await axios.put(
                    `/app/update/${articleId}`,
                    {
                        titre_Articles: articleData.titre_Articles,
                        texte_Articles: articleData.texte_Articles,
                        texte2_Articles: articleData.texte2_Articles,
                        texte3_Articles: articleData.texte3_Articles,
                        date_Articles: articleData.date_Articles,
                        auteur_Articles: articleData.auteur_Articles,
                        image_Articles: articleData.image_Articles // Utiliser l'ancienne image si elle existe
                    }
                );
    
                if (responseArticle.data.articleData) {
                    console.log(responseArticle.data.message);
                    console.log('Updated Article Data:', responseArticle.data.articleData);
    
                } else {
                    console.log("Aucune donnée d'article mise à jour reçue");
                }
                onClose();
                window.location.reload();
            }
        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'article", error);
        }
    };

    const handleInputChange = (e) => {
        setArticleId(e.target.value);
    };

    const handleImageChange = (e) => {
        // Vérifier si des fichiers ont été sélectionnés
        setNewImage(e.target.files[0]);
    };

    return (
        <div className="modalForm">
            <div className="position-modalForm">
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}>
                        <RxCross2 className="iconeCross" />
                    </div>
                    {!showForm ? (
                        <>
                            <h2>Modifier un Article</h2>
                            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                <label>
                                    Entrez l'ID de l'article à modifier :
                                    <input
                                        type="text"
                                        value={articleId}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <button type="button" onClick={handleGetArticleClick}>
                                    Récupérer l'article
                                </button>
                            </form>
                        </>
                    ) : (
                        <>
                            <h2>Modifier un Article</h2>
                            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                <label>
                                    Titre de l'article :
                                    <input
                                        type="text"
                                        value={articleData.titre_Articles}
                                        onChange={(e) =>
                                            setArticleData({
                                                ...articleData,
                                                titre_Articles: e.target.value,
                                            })
                                        }
                                    />
                                </label>
                                <label>
                                    Texte de l'article :
                                    <textarea
                                        value={articleData.texte_Articles}
                                        onChange={(e) =>
                                            setArticleData({
                                                ...articleData,
                                                texte_Articles: e.target.value,
                                            })
                                        }
                                    />
                                </label>
                                <label>
                                    Deuxième texte de l'article :
                                    <textarea
                                        value={articleData.texte2_Articles}
                                        onChange={(e) =>
                                            setArticleData({
                                                ...articleData,
                                                texte2_Articles: e.target.value,
                                            })
                                        }
                                    />
                                </label>
                                <label>
                                    Troisième texte de l'article :
                                    <textarea
                                        value={articleData.texte3_Articles}
                                        onChange={(e) =>
                                            setArticleData({
                                                ...articleData,
                                                texte3_Articles: e.target.value,
                                            })
                                        }
                                    />
                                </label>
                                <label>
                                    Date de l'article :
                                    <input
                                        type="text"
                                        value={articleData.date_Articles}
                                        onChange={(e) =>
                                            setArticleData({
                                                ...articleData,
                                                date_Articles: e.target.value,
                                            })
                                        }
                                    />
                                </label>
                                <label>
                                    Auteur de l'article :
                                    <input
                                        type="text"
                                        value={articleData.auteur_Articles}
                                        onChange={(e) =>
                                            setArticleData({
                                                ...articleData,
                                                auteur_Articles: e.target.value,
                                            })
                                        }
                                    />
                                </label>
                                <label>
                                    Modifier l'image :
                                    <input
                                        type="file"
                                        accept="image/*"
                                        name="image_Articles" // Assurez-vous que le nom correspond à celui attendu par Multer
                                        onChange={handleImageChange}
                                    />
                                </label>
                                <button type="submit">Modifier l'article</button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Update;
