import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';

const UpdatePresentationDeux = ({ onClose }) => {
    const presentationId = 1;    const [presentationData, setPresentationData] = useState({
        titre_Presentationdeux: '',
        sousTitre_Presentationdeux: '',
        texte_Presentationdeux: '',
        images_Presentationdeux: '',
    });

    const [newImage, setNewImage] = useState(null);

    const getPresentation = async () => {
        try {
            const response = await axios.get(
                `/app/getPresentationDeuxById/${presentationId}`
            );

            if (response.data.PresentationDeuxData) {
                setPresentationData(response.data.PresentationDeuxData);
                console.log('Nouvelles données récupérées :', response.data.PresentationDeuxData);
            } else {
                console.log('Aucune donnée reçue');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    useEffect(() => {
        getPresentation();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!presentationId) {
            console.error('ID de l\'article manquant');
            return;
        }

        try {
            const formData = new FormData();

            // Ajoutez la nouvelle image au FormData seulement si elle a été sélectionnée
            if (newImage) {
                formData.append('images_Presentationdeux', newImage);

                // Envoyez la requête pour télécharger la nouvelle image
                const responseImage = await axios.post(
                    '/app/uploadPresentationDeux',
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                // Obtenez le nom de la nouvelle image téléchargée
                const newImageName = responseImage.data.imageName;

                // Envoyez les données de l'article avec le nom de la nouvelle image
                const responseArticle = await axios.put(
                    `/app/updatePresentationDeux/${presentationId}`,
                    {
                        titre_Presentationdeux: presentationData.titre_Presentationdeux,
                        sousTitre_Presentationdeux: presentationData.sousTitre_Presentationdeux,
                        texte_Presentationdeux: presentationData.texte_Presentationdeux,
                        images_Presentationdeux: newImageName,
                    }
                );

                if (responseArticle.data.PresentationData) {
                    console.log(responseArticle.data.message);
                    console.log('Updated Article Data:', responseArticle.data.PresentationData);

                    // Vous pouvez également ajouter d'autres actions ici en cas de succès
                } else {
                    console.log('Aucune donnée d\'article mise à jour reçue');
                }
            } else {
                // Si aucune nouvelle image n'a été sélectionnée, envoyez la requête sans l'image
                const responseArticle = await axios.put(
                    `/app/updatePresentationDeux/${presentationId}`,
                    {
                        titre_Presentationdeux: presentationData.titre_Presentationdeux,
                        sousTitre_Presentationdeux: presentationData.sousTitre_Presentationdeux,
                        texte_Presentationdeux: presentationData.texte_Presentationdeux,
                        images_Presentationdeux: presentationData.images_Presentationdeux,
                    }
                );

                if (responseArticle.data.PresentationData) {
                    console.log(responseArticle.data.message);
                    console.log('Updated Article Data:', responseArticle.data.PresentationData);

                    // Vous pouvez également ajouter d'autres actions ici en cas de succès
                } else {
                    console.log('Aucune donnée d\'article mise à jour reçue');
                }
            }

            onClose();
            // window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'article', error);
        }
    };

    const handleImageChange = (e) => {
        setNewImage(e.target.files[0]);
    };

    return (
        <div className="modalForm">
            <div className="position-modalForm">
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}>
                        <RxCross2 className="iconeCross" />
                    </div>
                    <h2>Modifier la présentation</h2>
                    <form encType="multipart/form-data" onSubmit={handleSubmit}>
                            <input
                                type="text"
                                value={presentationData.titre_Presentationdeux}
                                onChange={(e) =>
                                    setPresentationData({
                                        ...presentationData,
                                        titre_Presentationdeux: e.target.value,
                                    })
                                }
                            />
                             <input
                                type="text"
                                value={presentationData.sousTitre_Presentationdeux}
                                onChange={(e) =>
                                    setPresentationData({
                                        ...presentationData,
                                        sousTitre_Presentationdeux: e.target.value,
                                    })
                                }
                            />
                            <textarea
                                value={presentationData.texte_Presentationdeux}
                                onChange={(e) =>
                                    setPresentationData({
                                        ...presentationData,
                                        texte_Presentationdeux: e.target.value,
                                    })
                                }
                            />
                            <input
                                type="file"
                                accept="image/*"
                                name="images_Presentationdeux"
                                onChange={handleImageChange}
                            />
                
                        <button type="submit">Modifier la présentation</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdatePresentationDeux;
