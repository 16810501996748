import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Pagination from '../../components/Actualites/Pagination';

import './actualites.css';

const Actualites = () => {
  // État local pour stocker les articles, la page actuelle, et le nombre total de pages
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const articlesPerPage = 2; // Nombre d'articles par page

  // Effet qui s'exécute lorsque la page actuelle change
  useEffect(() => {
    // Requête API pour récupérer les articles paginés
    axios
      .get(`/app/getArticles?page=${currentPage}`)
      .then((response) => {
        if (response.data.articles && Array.isArray(response.data.articles)) {
          // Mise à jour de l'état avec les articles récupérés
          setArticles(response.data.articles);

          // Calcul du nombre total de pages en utilisant le nombre total d'articles
          const totalPages = Math.ceil(response.data.totalCount / articlesPerPage);

          // Mise à jour de l'état avec le nombre total de pages
          setTotalPages(totalPages);

             // Retarder le scrolling vers le haut après un court délai
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 100);
        } else {
          console.log("Les données ne sont pas reçues correctement : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage]); // Déclenche cet effet lorsque la page actuelle change

  // Fonction pour gérer le changement de page
  const handlePageChange = (newPage) => {
    // Met à jour l'état avec la nouvelle page
    setCurrentPage(newPage);
  };

  return (
    <section className='actualites'>
      <h1>Actualités</h1>
      <div className='articles'>
        {/* Affichage des articles récupérés */}
        {articles.map((article) => (
          <div key={article.id_Articles} className='article'>
            <h2>{article.titre_Articles}</h2>
            <img src={`/images/articles/${article.image_Articles}`} alt={article.titre_Articles} />

            <p>{article.texte_Articles}</p>
            <p>{article.texte2_Articles}</p>
            <p>{article.texte3_Articles}</p>
            <p>Date: {article.date_Articles}</p>
            <p>Auteur: {article.auteur_Articles}</p>
          </div>
        ))}
      </div>
      {/* Affichage du composant de pagination si le nombre total de pages est supérieur à 0 */}
      {totalPages > 0 && (
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      )}
    </section>
  );
};

export default Actualites;




