import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate,} from 'react-router-dom';
import axios from 'axios';
import ScrollToTop from './components/ScollToTop';

import Header from './components/header/header';
import Footer from './components/footer/footer';
import Accueil from './pages/Accueil/Accueil';
import Services from './pages/Services/Services';
import QuiSuisJe from './pages/QuiSuisJe/QuiSuisJe';
import Seances from './pages/Seances/Seances';
import Actualites from './pages/Actualites/Actualites';
import Contact from './pages/Contact/Contact';
import Mentions from './pages/MentionsLegales/Mentions';

// CRUD
import Connexion from './pages/Dashboard/Connexion';
import Dashboard from './pages/Dashboard/Dashboard';
import UpdateAccueil from './pages/Dashboard/pages/Accueil';
import UpdateQuiSuisJe from './pages/Dashboard/pages/QuiSuisJe';
import UpdateActualites from './pages/Dashboard/pages/Actualites';
import UpdateContact from './pages/Dashboard/pages/Contact';
import Messagerie from './pages/Dashboard/pages/Messagerie';

import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem("token");

      try {
        if (token) {
          await axios.get('/auth/dashboards', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setIsAuthenticated(true);
        }
      } catch (error) {
        localStorage.removeItem("token");
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkTokenValidity();
  }, []);

  // Si isLoading est true, le composant retourne null (pas de rendu du contenu du composant pendant la vérification du token)
  if (isLoading) {
    return null;
  }

  return (
    <div className="App">
      <Router>
        <ScrollToTop/>
        <Routes>
            <>
              <Route path="/" element={<><Header /><Accueil /><Footer /></>} />
              <Route path="/QuiSuisJe" element={<><Header /><QuiSuisJe /><Footer /></>} />
              <Route path="/MesServices" element={<><Header /><Services /><Footer /></>} />
              <Route path="/seances/:id" element={<><Header /><Seances /><Footer /></>} />
              <Route path="/Actualites" element={<><Header /><Actualites /><Footer /></>} />
              <Route path="/Contact" element={<><Header /><Contact /><Footer /></>} />
              <Route path="/Mentions_Legales" element={<><Header /><Mentions /><Footer /></>} />
            </>
          {/* Route de connexion */}
          <Route
            path="/JeMeConnecte"
            element={
              isAuthenticated ? (
                // Si l'utilisateur est authentifié, redirection sur la page Dashboard
                <Navigate to="/Dashboard"/>
              ) : (
                // Si l'utilisateur n'est pas authentifié, j'affiche le composant Connexion
                <Connexion onLogin={() => setIsAuthenticated(true)} />
              )
            }
          />
          {/* Routes privées */}
          {isAuthenticated ? (
            <>
              <Route path="/Dashboard/*" element={<Dashboard />} />
              <Route path="/updateAccueil/*" element={<UpdateAccueil />} />
              <Route path="/updateQuiSuisJe/*" element={<UpdateQuiSuisJe />} />
              <Route path="/updateActualites/*" element={<UpdateActualites />} />
              <Route path="/updateContact/*" element={<UpdateContact />} />
              <Route path="/Messagerie" element={<Messagerie />} />
            </>
          ) : (
            <>
              <Route path="/Dashboard" element={<Navigate to="/JeMeConnecte" />} />
              <Route path="/updateAccueil" element={<Navigate to="/JeMeConnecte" />} />
              <Route path="/updateQuiSuisJe" element={<Navigate to="/JeMeConnecte" />} />
              <Route path="/updateActualites" element={<Navigate to="/JeMeConnecte" />} />
              <Route path="/updateContact" element={<Navigate to="/JeMeConnecte" />} />
              <Route path="/Messagerie" element={<Navigate to="/JeMeConnecte" />} />
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
