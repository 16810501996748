import { useEffect, useState } from "react";
import axios from 'axios';

import {AiOutlineCheck} from 'react-icons/ai';

const Objectifs = () => {
    const [objectifs, setObjectifs] = useState([]);

    

  useEffect(() => {
    axios.get('/app/getObjectifs')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setObjectifs(response.data);
          // console.log(response.data);
        } else {
          console.log("les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="blockObjectifs">
          <h4>Mes objectifs :</h4>
          <div className="objectifs">
          {objectifs.map((objectifs) => (
            <div className="positionCheck" key={objectifs.id_Objectifs}>
                <span className="check"><AiOutlineCheck/></span><p>{objectifs.texte_Objectifs}</p>
            </div>
          ))}
          </div>
        </div>
  )
}

export default Objectifs