import React, { useState } from 'react';
import axios from 'axios';
import { RxCross2 } from "react-icons/rx";

const Delete = ({ onClose }) => {
  const [articleId, setArticleId] = useState('');
  const [error, setError] = useState('');

  const handleDelete = async () => {
    try {
      // Validation minimale côté client
      if (!articleId) {
        setError('Veuillez entrer un ID d\'article');
        return;
      }
  
      console.log('ID de l\'article à supprimer :', articleId);
  
      // Utilisez Axios pour envoyer une requête de suppression à votre API
      await axios.delete(`/app/deleteArticle/${articleId}`);
  
      // Fermer la modal
      onClose();
      alert('Article supprimé avec succès');
      // window.location.reload();
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'article', error);
      setError('Erreur lors de la suppression de l\'article. Veuillez réessayer.');
    }
  };
  

  return (
    <div className="modalForm">
      <div className='position-modalForm'>
        <div className="contenu-modalForm">
          <div className="closeButton" onClick={onClose}><RxCross2 className='iconeCross' /></div>
          <h2>Supprimer un Article</h2>
          <form>
          <label>
            Entrez l'ID de l'article à supprimer :
            <input
              type="text"
              value={articleId}
              onChange={(e) => setArticleId(e.target.value)}
            />
          </label>
          {error && <p className="error-message">{error}</p>}
          
         
          </form>
          <button className='button' onClick={handleDelete}>Supprimer</button>
        </div>
      </div>
    </div>
  );
};

export default Delete;
