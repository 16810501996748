import { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';



const Presentation = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('/app/getPresentation')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setData(response.data);
          // console.log(response.data);
        } else {
          console.log("les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      {data.map((presentation) => (
        <div key={presentation.id_presentation}>
          <h1>{presentation.titre_presentation}</h1>
          <div className='blockPresentation' >

            <img id="PhotoRegine" src={`/images/Accueil/${presentation.image_presentation}`} alt="" />

            <div>
              <p>{presentation.texte_presentation}</p>
              <div className='button'>
                <Link to="/QuiSuisJe"><button>En savoir plus</button></Link>
              </div>
            </div>
          </div>

        </div>
      ))}
    </div>
  )
}

export default Presentation;