import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';

const UpdatePresentation = ({ onClose }) => {
    const presentationId = 1;
    const [presentationData, setPresentationData] = useState({
        titre_Qui_suis_je: '',
        texte_Qui_suis_je: '',
        images_Qui_suis_je: '',
    });

    const [newImage, setNewImage] = useState(null);

    const getPresentation = async () => {
        try {
            const response = await axios.get(
                `/app/getQuiSuisJeById/${presentationId}`
            );

            if (response.data.PresentationData) {
                setPresentationData(response.data.PresentationData);
                // console.log('Nouvelles données récupérées :', response.data.PresentationData);
            } else {
                console.log('Aucune donnée reçue');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    useEffect(() => {
        getPresentation();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!presentationId) {
            console.error('ID de l\'article manquant');
            return;
        }

        try {
            const formData = new FormData();

            // Ajoutez la nouvelle image au FormData seulement si elle a été sélectionnée par l'utilisateur
            if (newImage) {
                // Append est une méthode de l'objet FormData qui permet d'ajouter une paire clé/valeur
                // Dans ce cas, 'images_Qui_suis_je' est la clé, et newImage est la valeur (l'image sélectionnée par l'utilisateur)
                formData.append('images_Qui_suis_je', newImage);

                // Envoyez la requête pour télécharger la nouvelle image vers l'endpoint '/accueil/uploadPresentation'
                const responseImage = await axios.post(
                    '/app/uploadQuiSuisJe',
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (responseImage.data.imageName) {
                    const newImageName = responseImage.data.imageName;

                      // Envoyez les données de l'article avec le nom de la nouvelle image
                const responseArticle = await axios.put(
                    `/app/updateQuiSuisJe/${presentationId}`,
                    {
                        titre_Qui_suis_je: presentationData.titre_Qui_suis_je,
                        texte_Qui_suis_je: presentationData.texte_Qui_suis_je,
                        images_Qui_suis_je: newImageName,
                    }
                );

                if (responseArticle.data.PresentationData) {
                    console.log(responseArticle.data.message);
                    console.log('Updated Article Data:', responseArticle.data.PresentationData);

                    // Vous pouvez également ajouter d'autres actions ici en cas de succès
                } else {
                    console.log('Aucune donnée d\'article mise à jour reçue');
                }
                }
              } else {
                // Si aucune nouvelle image n'a été sélectionnée, envoyez la requête sans l'image
                const responseArticle = await axios.put(
                    `/app/updateQuiSuisJe/${presentationId}`,
                    {
                        titre_Qui_suis_je: presentationData.titre_Qui_suis_je,
                        texte_Qui_suis_je: presentationData.texte_Qui_suis_je,
                        images_Qui_suis_je: presentationData.images_Qui_suis_je,
                    }
                );

                if (responseArticle.data.PresentationData) {
                    console.log(responseArticle.data.message);
                    console.log('Updated Article Data:', responseArticle.data.PresentationData);
                } else {
                    console.log('Aucune donnée d\'article mise à jour reçue');
                }
            }
            onClose();
            // window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'article', error);
        }
    };

    const handleImageChange = (e) => {
        setNewImage(e.target.files[0]);
    };

    return (
        <div className="modalForm">
            <div className="position-modalForm">
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}>
                        <RxCross2 className="iconeCross" />
                    </div>
                    <h2>Modifier la présentation</h2>
                    <form encType="multipart/form-data" onSubmit={handleSubmit}>

                        <input
                            type="text"
                            value={presentationData.titre_Qui_suis_je}
                            onChange={(e) =>
                                setPresentationData({
                                    ...presentationData,
                                    titre_Qui_suis_je: e.target.value,
                                })
                            }
                        />



                        <textarea
                            value={presentationData.texte_Qui_suis_je}
                            onChange={(e) =>
                                setPresentationData({
                                    ...presentationData,
                                    texte_Qui_suis_je: e.target.value,
                                })
                            }
                        />


                        <input
                            type="file"
                            accept="image/*"
                            name="images_Qui_suis_je"
                            onChange={handleImageChange}
                        />

                        <button type="submit">Modifier la présentation</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdatePresentation;
