import axios from 'axios';
import React, { useEffect, useState } from 'react'

const PresentationContact = () => {

    const [presentation, setPresentation] = useState([])

    useEffect(() => {
        axios.get('/app/getPresentationContact')
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setPresentation(response.data)
                    // console.log(response.data)
                }
                else {
                    console.log("Les données ne sont pas reçues : ", response.data)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])
  return (
    <>
    {presentation.map((presentation) => (
        <div key={presentation.id_Contact}>
            <h1>{presentation.titre_Contact}</h1>
            <div className='blockContact'>
                <p>{presentation.texte_Contact}</p>
                <p>{presentation.texte2_Contact}</p>
                <p>{presentation.cordonnees_Contact}</p>
            </div>
        </div>
    ))}
    </>
  )
}

export default PresentationContact