import axios from 'axios';
import React, { useEffect, useState } from 'react'

import '../../pages/QuiSuisJe/QuiSuisJe.css'

const Diplomes = () => {

    const [diplomes, setDiplomes] = useState([]);

    useEffect(() => {
        axios.get('/app/getDiplomes')
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setDiplomes(response.data)
                    // console.log(response.data)
                }
                else {
                    console.log("Les données ne sont pas reçues : ", response.data)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])
    return (
        <div className='blockQuiSuisJe'>
            <div className='legend'>Diplômes et Formations</div>
            {diplomes.map((diplomes) => (
                <div className='blockDiplomes' key={diplomes.id_Diplomes}>
                    <span className='diplomes'>{diplomes.diplomes}</span>
                    <span className='texteDiplomes'>{diplomes.texte}</span>
                </div>
            ))}
        </div>
    )
}

export default Diplomes