import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

import './style/dashboard.css'
import Deconnexion from './components/Deconnexion';
import NavigationPrivate from './components/NavigationPrivate';


const Dashboard = () => {

  return (
    <section className='dashboard'>
     <NavigationPrivate/>
      <h1>Tableau de bord</h1>
      <div className='explication'>
        <p>Bonjour Régine, voici votre tableau de bord pour mettre à jour les données de votre site,
          pour modifier une page précise veuillez sélectionner la page souhaitée ci-dessous.</p>
      </div>

      <div className='conteneurPage'>
        <Link to="/updateAccueil" className='Link'>
          <div className='blockPage'>
              <p>Accueil</p>
          </div>
        </Link>

        <Link to="/updateQuiSuisJe" className='Link'>
          <div className='blockPage'>
              <p>Qui suis je</p>
          </div>
        </Link>

        <Link to="/updateActualites" className='Link'>
          <div className='blockPage'>
              <p>Actualites</p>
          </div>
        </Link>

        <Link to="/updateContact" className='Link'>
          <div className='blockPage'>
              <p>Contact</p>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default Dashboard;






