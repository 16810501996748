import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavigationPrivate from '../components/NavigationPrivate';
import MessageModal from '../components/MessageModal';
import '../style/messagerie.css';

const Messagerie = () => {
  const [nouveauMessage, setNouveauMessage] = useState([]);
  const [ancienMessage, setAncienMessage] = useState([]);

  // État pour le message sélectionné dans la modal
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Récupérer les nouveaux messages
  useEffect(() => {
    axios.get('/app/getNouveauMessage')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setNouveauMessage(response.data);
          console.log(response.data);
        } else {
          console.log("Les données n'ont pas été reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Récupérer les anciens messages
  useEffect(() => {
    axios.get('/app/getAncienMessage')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setAncienMessage(response.data);
          console.log(response.data);
        } else {
          console.log("Les données n'ont pas été reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Retirer un message des nouveaux messages
  const removeFromNouveauMessages = (messageId) => {
    setNouveauMessage((prevMessages) => prevMessages.filter((message) => message.id_message !== messageId));
  };

  // Mettre à jour les nouveaux messages
  const updateNouveauMessagesCount = async () => {
    try {
      const response = await axios.get('/app/getNouveauMessage');
      if (Array.isArray(response.data)) {
        console.log('Nouveaux messages mis à jour :', response.data);
        setNouveauMessage(response.data);  // Mettre à jour le tableau des nouveaux messages
      } else {
        console.log("Les données n'ont pas été reçues : ", response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Mettre à jour les anciens messages
  const updateAncienMessages = async () => {
    try {
      const response = await axios.get('/app/getAncienMessage');
      if (Array.isArray(response.data)) {
        setAncienMessage(response.data);
        console.log(response.data);
      } else {
        console.log("Les données n'ont pas été reçues : ", response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Ouvrir la modal et effectuer les mises à jour nécessaires
  const openModal = async (message) => {
    // Marquer le message comme lu
    await axios.put(`/app/messageLu/${message.id_message}`)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });

    // Déplacer le message vers les anciens messages
    await axios.post(`/app/moveToOldMessages/${message.id_message}`)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });

    // Retirer le message des nouveaux messages
    removeFromNouveauMessages(message.id_message);

    // Définir le message sélectionné et afficher la modal
    setSelectedMessage(message);
    setShowModal(true);

    // Mettre à jour le nombre de nouveaux messages
    updateNouveauMessagesCount();
  };

  // Fermer la modal et mettre à jour les anciens messages
  const closeModal = async () => {
    setSelectedMessage(null);
    setShowModal(false);
    // Mettre à jour les anciens messages
    updateAncienMessages();
    // Mettre à jour le nombre de nouveaux messages à la fermeture de la modal
    updateNouveauMessagesCount();
  };

  return (
    <>
      {showModal && (
        <MessageModal
          message={selectedMessage}
          onClose={closeModal}
        />
      )}
      <section className='Messagerie'>
        <NavigationPrivate />
        <h1>Messagerie</h1>

        <p className='presentation'>
        Voici votre boîte de messagerie, qui vous permettra de stocker les messages reçus ainsi que les anciens messages
         via le formulaire de contact sur votre site. Les anciens messages sont triés du plus récent au plus ancien.
        </p>

        <div className='blockMessagerie'>
          <div className='newMessage'>
            <p className='sousTitre'>Nouveaux Message</p>
            <div className='blockNewMessage'>
              {nouveauMessage.map((message) => (
                <div key={message.id_message} className='conteneurMessage' onClick={() => openModal(message)}>
                  <p>
                    Vous avez un nouveau message de : {`${message.nom_expediteur} ${message.prenom_expediteur}`}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className='oldMessage'>
            <p className='sousTitre'>Historique des messages</p>
            <div className='blockNewMessage'>
              {ancienMessage.map((message) => (
                <div key={message.id_message} className='conteneurMessage' onClick={() => openModal(message)}>
                  <p>
                    Message de : {`${message.nom_expediteur} ${message.prenom_expediteur}`}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Messagerie;
