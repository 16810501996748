import React, { useState, useEffect } from "react";
import axios from "axios";
import { RxCross2 } from "react-icons/rx";

const Update = ({ onClose }) => {
    const [id_CarrouselImg, setId_CarrouselImg] = useState("");
    const [carrouselData, setCarrouselData] = useState({
        citation_CarrouselImg: "",
        auteur_CarrouselImg: "",
        images_CarrouselImg: "",
    });


    const [showForm, setShowForm] = useState(false);
    const [newImage, setNewImage] = useState(null);

    const handleGetCarrouselClick = async () => {
        try {
            const response = await axios.get(
                `/app/getCarrouselById/${id_CarrouselImg}`
            );

            if (response.data.CarrouselData) {
                setCarrouselData(response.data.CarrouselData);
                setShowForm(true);
                console.log(response.data.carrouselData);
            } else {
                console.log("Aucune donnée d'article reçue");
            }
        } catch (error) {
            console.error(
                "Erreur lors de la récupération des données de l'article pour la modification",
                error
            );
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!id_CarrouselImg) {
            console.error("ID de l'article manquant");
            return;
        }
    
        try {
            // FormData pour gérer l'envoi d'images
            const formData = new FormData();
    
            // Ajout de la nouvelle image seulement si elle existe
            if (newImage) {
                formData.append("images_CarrouselImg", newImage);
    
                const responseImage = await axios.post(
                    "/app/uploadCarrousel",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (responseImage.data.imageName) {
                    console.log('Image Upload Response:', responseImage.data);

                     // Récupération du nom de la nouvelle image
                const newImageName = responseImage.data.imageName;

                console.log(newImageName);


                const responseCarrousel = await axios.put(
                    `/app/updateCarrousel/${id_CarrouselImg}`,
                    {
                        citation_CarrouselImg: carrouselData.citation_CarrouselImg,
                        auteur_CarrouselImg: carrouselData.auteur_CarrouselImg,
                        // Ajout de l'image seulement si elle existe
                        images_CarrouselImg: newImageName
                    }
                );

                if (responseCarrousel.data.carrouselData) {
                    console.log(responseCarrousel.data.message);
                    console.log('Updated Article Data:', responseCarrousel.data.articleData);
                } else {
                    console.log("Aucune donnée d'article mise à jour reçue");
                }
                } 
            }else {
                // Ajouter le bloc else pour l'ancienne image
                const responseCarrousel = await axios.put(
                    `/app/updateCarrousel/${id_CarrouselImg}`,
                    {
                        citation_CarrouselImg: carrouselData.citation_CarrouselImg,
                        auteur_CarrouselImg: carrouselData.auteur_CarrouselImg,
                        // Utiliser l'ancienne image si elle existe
                        images_CarrouselImg: carrouselData.images_CarrouselImg
                    }
                );
    
                if (responseCarrousel.data.carrouselData) {
                    console.log(responseCarrousel.data.message);
                    console.log('Updated Article Data:', responseCarrousel.data.articleData);
                } else {
                    console.log("Aucune donnée d'article mise à jour reçue");
                }
            }
    
            onClose();
            // window.location.reload();
        } catch (error) {
            console.error("Erreur lors de la mise à jour", error);
        }
    };
    
    

    const handleInputChange = (e) => {
        setId_CarrouselImg(e.target.value);
    };

    const handleImageChange = (e) => {
        // Vérifier si des fichiers ont été sélectionnés
    
            setNewImage(e.target.files[0]);
      
    };

    return (
        <div className="modalForm">
            <div className="position-modalForm">
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}>
                        <RxCross2 className="iconeCross" />
                    </div>
                    {!showForm ? (
                        <>
                            <h2>Modifier les données du carrousel</h2>
                            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                <label>
                                    Entrez l'ID de l'image et citation à modifier :
                                    <input
                                        type="text"
                                        value={id_CarrouselImg}
                                        onChange={handleInputChange}
                                    />
                                </label>
                                <button type="button" onClick={handleGetCarrouselClick}>
                                    Récupération de l'ID
                                </button>
                            </form>
                        </>
                    ) : (
                        <>
                            <h2>Modifier un Article</h2>
                            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                                <label>
                                    Citation : 
                                    <input
                                        type="text"
                                        value={carrouselData.citation_CarrouselImg}
                                        onChange={(e) =>
                                            setCarrouselData({
                                                ...carrouselData,
                                                citation_CarrouselImg: e.target.value,
                                            })
                                        }
                                    />
                                </label>
                                <label>
                                   Auteur de la citation :
                                    <textarea
                                        value={carrouselData.auteur_CarrouselImg}
                                        onChange={(e) =>
                                            setCarrouselData({
                                                ...carrouselData,
                                                auteur_CarrouselImg: e.target.value,
                                            })
                                        }
                                    />
                                </label>
                                <label>
                                    Modifier l'image :
                                    <input
                                        type="file"
                                        accept="image/*"
                                        name="images_CarrouselImg"
                                        onChange={handleImageChange}
                                    />
                                </label>
                                <button type="submit">Modifier</button>
                            </form>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Update;
