import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';

const UpdateQuestions = ({ onClose, questionId }) => {
  const [questionData, setQuestionData] = useState({
    id_Questions: '', // Ajoutez l'ID de la question
    question_Questions: '',
    reponse_Questions: '',
  });

  useEffect(() => {
    if (questionId) {
      axios
        .get(`/app/getQuestionById/${questionId}`)
        .then((response) => {
          setQuestionData((prevData) => ({
            ...prevData,
            id_Questions: response.data.QuestionData.id_Questions,
            question_Questions: response.data.QuestionData.question_Questions,
            reponse_Questions: response.data.QuestionData.reponse_Questions,
          }));
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des détails de la question', error);
        });
    }
  }, [questionId, setQuestionData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuestionData({
      ...questionData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`/app/updateQuestion/${questionData.id_Questions}`, questionData);

      alert('Question modifiée avec succès!');

      onClose();
      // window.location.reload();
    } catch (error) {
      console.error('Erreur lors de la modification de la question', error);
      // Ajouter ici la gestion des erreurs
    }
  };

  return (
    <div className="modalForm">
      <div className="position-modalForm">
        <div className="contenu-modalForm">
          <div className="closeButton" onClick={onClose}>
            <RxCross2 className="iconeCross" />
          </div>
          <h2>Modifier la question</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="question_Questions"
              value={questionData.question_Questions}
              onChange={handleInputChange}
              placeholder="Question fréquente"
            />
            <input
              type="text"
              name="reponse_Questions"
              value={questionData.reponse_Questions}
              onChange={handleInputChange}
              placeholder="Réponse"
            />
            <button type="submit">Modifier</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateQuestions;
