// MessageModal.jsx
import React from 'react';
import he from 'he'; // Pour échapper les données du formulaire

import '../style/messagerie.css'

const MessageModal = ({ message, onClose }) => {
  const decodedContenu = he.decode(message.contenu);
  const dateEnvoi = new Date(message.date_envoi);
  const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('fr-FR', options).format(dateEnvoi);

  const mailtoLink = `mailto:${message.mail_expediteur}`;

  return (
    <div className="modal">
      <div className="modal-content">
        <div className='description-modal'>
          <span className="close" onClick={onClose}>&times;</span>
          <div className='contenu-modal'>
            <div className='position-contenu'>
              <p className='titre'>Expéditeur :</p>
              <p className='texte'> {`${message.nom_expediteur} ${message.prenom_expediteur}`}</p>
            </div>

            <div className='position-contenu'>
              <p className='titre'>Contenu du message :</p>
              <p className='texte'> {decodedContenu}</p>
            </div>

            <div className='position-contenu'>
              <p className='titre'>Mail de l'expéditeur</p>
              <p className='texte'>
                <a href={mailtoLink}>{message.mail_expediteur}</a>
              </p>
            </div>

            <div className='position-date'>
              <p>Reçu le : {formattedDate}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageModal;
