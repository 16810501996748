import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className='blockPagination'>
      {/* Bouton précédent, désactivé s'il s'agit de la première page */}
      <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage <= 1}>
        Précédent
      </button>
      {/* Affichage de la page actuelle et du nombre total de pages */}
      <span>{`Page ${currentPage} sur ${totalPages}`}</span>
      {/* Bouton suivant, désactivé s'il s'agit de la dernière page */}
      <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage >= totalPages}>
        Suivant
      </button>
    </div>
  );
};

export default Pagination;

