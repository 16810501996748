import React from 'react';
import axios from 'axios';
import { RxCross2 } from "react-icons/rx";

const DeletePsychosociologie = ({ onClose, id_Psychosociologie }) => {

    console.log(id_Psychosociologie);


    const handleDelete = async () => {
        try {
            if (!id_Psychosociologie) {
                   console.log("Erreur d'id");
                   return
            }

            await axios.delete(`/app/deletePsychosociologie/${id_Psychosociologie}`)

            onClose();
            alert('Approche Psychosociologique supprimée avec succès')
            // window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la suppression', error);

        }
    }

    return (
        <div className="modalForm">
            <div className='position-modalForm'>
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}><RxCross2 className='iconeCross' /></div>
                    <h2>Supprimer l'approche </h2>
                    <div className='conteneurDelete'>
                        <p>Etes vous sur de vouloir supprimer cette approche?</p>
                        <div className='conteneurBtnDelete'>
                        <button onClick={handleDelete}>Oui</button>
                        <button onClick={onClose}>Non</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeletePsychosociologie