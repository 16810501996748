import React, { useEffect, useState } from 'react';
import './Slider.css';
import axios from 'axios';

// Composant Slider
const Slider = ({ showID }) => {
    const [data, setData] = useState([]);
    const [slideIndex, setSlideIndex] = useState(1);

    // requête HTTP au chargement du composant pour obtenir les données du carrousel
    useEffect(() => {
        axios.get('/app/getCarrousel')
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setData(response.data);
                    // console.log(response.data);
                    // console.log(response.data.length);
                } else {
                    console.log("les données ne sont pas reçues : ", response.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []); // Le tableau vide [] signifie que ce hook ne s'exécute qu'une seule fois au montage du composant

    // Effet pour gérer le défilement automatique des slides
    useEffect(() => {
        const autoSlide = () => {
            if (slideIndex >= data.length) {
                setSlideIndex(1);
            } else {
                setSlideIndex(slideIndex + 1);
            }
        };

        // Démarre un intervalle pour le défilement automatique
        const interval = setInterval(autoSlide, 4300);

        // Nettoie l'intervalle lorsque le composant est démonté ou lorsque slideIndex ou data changent
        return () => {
            clearInterval(interval);
        };
    }, [slideIndex, data]);

    // Rendu du composant Slider
    return (
        <div className='container-slider'>
            {data.map((item, index) => (
                <div key={item.id_CarrouselImg} className={`slide ${index + 1 === slideIndex ? 'active-anim' : ''}`}>
                    <div className='block-id'>
                        {showID && <p className="id-indicator">ID: {item.id_CarrouselImg}</p>}
                    </div>

                    <img src={`/images/Carrousel/${item.images_CarrouselImg}`} alt={item.images_CarrouselImg} loading="lazy" />
                    <div className='container-citation'>
                        <div className='citation-position'>
                            <p>{item.citation_CarrouselImg}</p>
                            <p id='flex-end'>{item.auteur_CarrouselImg}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Slider;