import React, { useState, useEffect } from 'react';
import PresentationContact from '../../../components/Contact/PresentationContact';
import Update from '../components/form/Contact/Update';
import AddQuestions from '../components/form/Contact/AddQuestions';
import UpdateQuestions from '../components/form/Contact/UpdateQuestions';
import DeleteQuestions from '../components/form/Contact/DeleteQuestions';
import NavigationPrivate from '../components/NavigationPrivate';
import axios from 'axios';
import '../style/contact.css';

const Contact = () => {
  const [showUpdateForm, setUpdateForm] = useState(false);
  const [showAddQuestionsForm, setShowAddQuestionsForm] = useState(false)
  const [showUpdateQuestionForm, setShowUpdateQuestionForm] = useState(false)
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(false)
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    axios
      .get('/app/getQuestions')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setQuestions(response.data);
          // console.log(response.data);
        } else {
          console.log('Les données ne sont pas reçues : ', response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleBtnUpdateQuestions = (questionId) => {
    setSelectedQuestionId(questionId);
    setShowUpdateQuestionForm(true)
  };



  const handleCloseUpdateQuestions = () => {
    setSelectedQuestionId(null);
    setShowUpdateQuestionForm(false);
  };

  const handleBtnDelete = (questionId) => {
    setSelectedQuestionId(questionId)
    setShowDeleteQuestion(true)
  }

  const handleCloseDelete = () => {
    setSelectedQuestionId(null);
    setShowDeleteQuestion(false)

  }

  const handleBtnUpdate = () => setUpdateForm(true)
  const handleCloseForm = () => setUpdateForm(false)

  const handleBtnAddQuestions = () => setShowAddQuestionsForm(true)
  const handleCloseAddQuestions = () => setShowAddQuestionsForm(false)

  // const handleBtnDelete = (questionId) => {
  //   // Ajoutez ici la logique de suppression en utilisant l'ID de la question
  //   console.log(`Supprimer la question avec l'ID : ${questionId}`);
  // };

  return (
    <>
      {showUpdateForm && <Update onClose={handleCloseForm} />}
      {showAddQuestionsForm && <AddQuestions onClose={handleCloseAddQuestions} />}
      {showUpdateQuestionForm && <UpdateQuestions onClose={handleCloseUpdateQuestions} questionId={selectedQuestionId} />}
      {showDeleteQuestion && <DeleteQuestions onClose={handleCloseDelete} questionId={selectedQuestionId} />}
      <NavigationPrivate />
      <section className="Contact crud">
        <h1>Gestion de la page Contact</h1>
        <p id="texteUpdate">
          Présentation de la page Contact, cliquer sur le bouton "Modifier" pour faire
          apparaître le formulaire de modification.
        </p>

        <div className="conteneurUpdate">
          <div className='conteneurBtnUpdate'>
            <button onClick={handleBtnUpdate}>Modifier</button>
          </div>
          <PresentationContact />
        </div>

        <div className="conteneurUpdateQuestion">
          <div>
            <p id='texteQuestions'>Ci-dessous se trouve la liste des questions fréquentes,
              vous pouvez ajouter une nouvelle question, la modifier ou la supprimer.
            </p>
          </div>

          <div className='conteneurBtnInsert'>
            <button onClick={handleBtnAddQuestions}>Ajouter une question fréquente</button>
          </div>
          <div className="conteneurQuestion">

            {questions.map((question) => (
              <div key={question.id_Questions} className="questionBlock">
                <p>{question.question_Questions}</p>
                <p>{question.reponse_Questions}</p>
                <div className="questionActions">
                  <button onClick={() => handleBtnUpdateQuestions(question.id_Questions)} >Modifier</button>
                  <button onClick={() => handleBtnDelete(question.id_Questions)}>
                    Supprimer
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
