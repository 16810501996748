import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';

const Questions = () => {
  // État pour stocker les données des questions
  const [questions, setQuestions] = useState([]);

  // Effet au montage pour charger les données des questions depuis l'API
  useEffect(() => {
    axios
      .get('/app/getQuestions')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setQuestions(response.data);
        } else {
          console.log('Les données ne sont pas reçues : ', response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []); // Utilisez une dépendance vide pour effectuer cette action une seule fois au montage

  // État pour stocker les IDs des questions
  const [expandedQuestionIds, setExpandedQuestionIds] = useState([]);

  // Fonction pour basculer l'état d'une question
  const toggleQuestion = (questionId) => {
    if (expandedQuestionIds.includes(questionId)) {
      // Si l'ID est déjà dans le tableau, le retirer pour réduire la question
      setExpandedQuestionIds(expandedQuestionIds.filter((id) => id !== questionId));
    } else {
      // Sinon, ajoutez l'ID au tableau pour développer la question
      setExpandedQuestionIds([...expandedQuestionIds, questionId]);
    }
  };

  return (
    <div>
      {/* Titre des questions fréquentes */}
      <p id="titreQuestion">Questions fréquentes</p>
      
      {/* Conteneur pour afficher les questions et réponses */}
      <div className="containerQuestion">
        {/* Map à travers toutes les questions pour les afficher */}
        {questions.map((question) => (
          <div className="blockQuestions" key={question.id_Questions}>
            <div className="questions">
              {/* Affiche la question */}
              <p>{question.question_Questions}</p>
              
              {/* Icône pour développer ou réduire la question */}
              <span
                className={`iconeQuestions ${expandedQuestionIds.includes(question.id_Questions) ? 'expanded' : ''}`}
                onClick={() => toggleQuestion(question.id_Questions)}
              >
                <AiOutlinePlus />
              </span>
            </div>
            
            {/* Affiche la réponse si la question est développée */}
            {expandedQuestionIds.includes(question.id_Questions) && (
              <p className={`reponse ${expandedQuestionIds.includes(question.id_Questions) ? 'fade-in' : ''}`}>
                {question.reponse_Questions}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Questions;
