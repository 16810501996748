import { useEffect, useState } from "react";
import axios from 'axios';

import { Link } from 'react-router-dom';

import {AiOutlineCheckSquare} from 'react-icons/ai'

const PresentationDeux = () => {

  const [presentation, setPresentation] = useState([]);
  

  useEffect(() => {
    axios.get('/app/getPresentationDeux')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setPresentation(response.data);
          // console.log(response.data);
        } else {
          console.log("les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [liste, setListe] = useState([]);

  useEffect(() => {
    axios.get('/app/getListePresentation')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setListe(response.data);
          // console.log(response.data);
        } else {
          console.log("les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      {presentation.map((presentation) => (
        <div key={presentation.id_Presentationdeux}>
          <h2>{presentation.titre_Presentationdeux}</h2>
          <h3>{presentation.sousTitre_Presentationdeux}</h3>
        </div>
      ))}
      <div className="blockListe">
      {liste.map((liste) => (
        <div key={liste.id_listePresentation} className="blockQuestion">
        <span><AiOutlineCheckSquare/></span><p>{liste.questions_listePresentation}</p>
        </div>
      ))}
      <p id="finListe">Alors le coaching est fait pour vous.</p>
      </div>
      {presentation.map((presentation) => (

        <div key={presentation.id_Presentationdeux} className='blockPresentation'>
          <div>
            <p id="margin">{presentation.texte_Presentationdeux}</p>
            <div className='button' id="btnDeux">
              <Link to="/MesServices"><button>Mes services</button></Link>
            </div>

          </div>

          <img src={`/images/Accueil/${presentation.images_Presentationdeux}`} alt="" />

        </div>

      ))}


    </div>

  )
}

export default PresentationDeux