import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';

const UpdateInterrogation = ({ onClose, id_listePresentation }) => {
    console.log(id_listePresentation);
  const [interrogationData, setInterrogartionData] = useState({
    id_listePresentation: '',
    questions_listePresentation: '',
  });

  useEffect(() => {
    if (id_listePresentation) {
      axios
        .get(`/app/getInterrogationById/${id_listePresentation}`)
        .then((response) => {
          setInterrogartionData((prevData) => ({
            ...prevData,
            id_listePresentation: response.data.QuestionData.id_listePresentation,
            questions_listePresentation: response.data.QuestionData.questions_listePresentation,
          }));
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des données', error);
        });
    }
  }, [id_listePresentation, setInterrogartionData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInterrogartionData({
      ...interrogationData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`/app/updateInterrogation/${interrogationData.id_listePresentation}`, interrogationData);

      alert('Interrogation modifiée avec succès!');

      onClose();
      // window.location.reload();
    } catch (error) {
      console.error('Erreur lors de la modification de l\'interrogation', error);
    }
  };

  return (
    <div className="modalForm">
      <div className="position-modalForm">
        <div className="contenu-modalForm">
          <div className="closeButton" onClick={onClose}>
            <RxCross2 className="iconeCross" />
          </div>
          <h2>Modifier l'interrogation</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="questions_listePresentation"
              value={interrogationData.questions_listePresentation}
              onChange={handleInputChange}
              placeholder="Interrogation : "
            />
            <button type="submit">Modifier</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateInterrogation;
