import React, { useState } from 'react';
import axios from 'axios';
import he from 'he'; // Pour échapper les données du formulaire

import { RxCross1 } from 'react-icons/rx';

const Formulaire = () => {
  // State pour gérer les données du formulaire
  const [formData, setFormData] = useState({
    nom_expediteur: '',
    prenom_expediteur: '',
    mail_expediteur: '',
    contenu: '',
  });

  const [errors, setErrors] = useState({});
  
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.nom_expediteur) {
      errors.nom_expediteur = 'Le nom est requis';
    } else if (!isValidData(formData.nom_expediteur)) {
      errors.nom_expediteur = 'Le nom ne doit pas contenir de chiffre';
    }

    if (!formData.prenom_expediteur) {
      errors.prenom_expediteur = 'Le prénom est requis';
    } else if (!isValidData(formData.prenom_expediteur)) {
      errors.prenom_expediteur = 'Le prénom ne doit pas contenir de chiffre';
    }

    if (!formData.mail_expediteur) {
      errors.mail_expediteur = "L'e-mail est requis";
    } else if (!isValidEmail(formData.mail_expediteur)) {
      errors.mail_expediteur = "L'e-mail n'est pas valide";
    }

    setErrors(errors);

    // Si aucune erreur, soumettre le formulaire
    if (Object.keys(errors).length === 0) {
      submitForm();
    }
  };

  // Fonction pour valider un email
  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  // Fonction pour valider les données (nom, prénom, etc.)
  const isValidData = (data) => {
    const dataPattern = /^[a-zA-Z-\s]+$/;
    return dataPattern.test(data);
  };

  // Fonction pour fermer la modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Fonction pour soumettre le formulaire
  const submitForm = () => {
    // Encodage des données du formulaire pour éviter les attaques XSS
    const escapedFormData = {
      nom_expediteur: he.encode(formData.nom_expediteur),
      prenom_expediteur: he.encode(formData.prenom_expediteur),
      mail_expediteur: he.encode(formData.mail_expediteur),
      contenu: he.encode(formData.contenu),
    };

    // Envoi des données encodées au serveur via une requête POST
    axios
      .post('/app/ajoutNewMessage', escapedFormData)
      .then((response) => {
        console.log('Formulaire soumis avec succès :', response.data);
        
        // Réinitialisation du formulaire et affichage de la modal
        setFormData({
          nom_expediteur: '',
          prenom_expediteur: '',
          mail_expediteur: '',
          contenu: '',
        });
        setShowModal(true);
      })
      .catch((error) => {
        console.error('Erreur lors de la soumission du formulaire :', error);
      });
  };

  // Rendu du composant Formulaire
  return (
    <div>
      <p id="titreContact">Formulaire de contact</p>
      <form onSubmit={(e) => { e.preventDefault(); validateForm(); }}>
        {/* Champs du formulaire avec gestion des erreurs */}
        <input
          type="text"
          name="nom_expediteur"
          value={formData.nom_expediteur}
          onChange={handleInputChange}
          placeholder="Votre nom :"
          required
        />
        {errors.nom_expediteur && <p className="error">{errors.nom_expediteur}</p>}

        <input
          type="text"
          name="prenom_expediteur"
          value={formData.prenom_expediteur}
          onChange={handleInputChange}
          placeholder="Votre prénom :"
          required
        />
        {errors.prenom_expediteur && <p className="error">{errors.prenom_expediteur}</p>}

        <input
          type="email"
          name="mail_expediteur"
          value={formData.mail_expediteur}
          onChange={handleInputChange}
          placeholder="Votre e-mail :"
          required
        />
        {errors.mail_expediteur && <p className="error">{errors.mail_expediteur}</p>}

        <textarea
          name="contenu"
          value={formData.contenu}
          onChange={handleInputChange}
          placeholder="Votre message ..."
          rows={8}
          required
        />
        {/* Bouton de soumission du formulaire */}
        <button type="submit">Envoyer</button>
      </form>
      
      {/* Modal de confirmation */}
      <div className={`containerPopup ${showModal ? 'show' : ''}`}>
        <div className="information">
          <span onClick={closeModal} className='blockIcone'>
            <RxCross1 className='iconeContact' />
          </span>
          <div className='blockInformation'>
            <p>Votre message a été reçu avec succès. Je vous remercie de m'avoir contactée.
               Je m'engage à vous répondre dans les meilleurs délais.</p>
            <p>L'envol By Régine</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Formulaire;
