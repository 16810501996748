import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';

const UpdatePsychosociale = ({ onClose, id_Psychosociales }) => {
    // console.log(id_Psychosociales);
    const [psychosocialeData, setPsychosocialeData] = useState({
        id_Psychosociales: '',
        approches: '',
        texte: '',
    });

    useEffect(() => {
        if (id_Psychosociales) {
            axios
                .get(`/app/getPsychosociale/${id_Psychosociales}`)
                .then((response) => {
                    // console.log(response);
                    // console.log(response.data);
                    setPsychosocialeData((prevData) => ({
                        ...prevData,
                        id_Psychosociales: response.data.Data.id_Psychosociales,
                        approches: response.data.Data.approches,
                        texte: response.data.Data.texte
                    }));
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des données', error);
                });
        }
    }, [id_Psychosociales, setPsychosocialeData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPsychosocialeData({
            ...psychosocialeData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.put(`/app/updatePsychosociales/${psychosocialeData.id_Psychosociales}`, psychosocialeData);

            alert('Approche Psychosociale modifiée avec succès!');

            onClose();
            // window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la modification du Diplôme', error);
        }
    };

    return (
        <div className="modalForm">
            <div className="position-modalForm">
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}>
                        <RxCross2 className="iconeCross" />
                    </div>
                    <h2>Modification des informations</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="approches"
                            value={psychosocialeData.approches || ''}
                            onChange={handleInputChange}
                            placeholder="Nom de l'approche"
                        />

                        <input
                            type="text"
                            name="texte"
                            value={psychosocialeData.texte || ''}
                            onChange={handleInputChange}
                            placeholder="texte"
                        />
                        <button type="submit">Modifier</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdatePsychosociale;
