import React, { useState } from 'react';
import axios from 'axios';

import "../../../style/modal.css"
import { RxCross2 } from "react-icons/rx";

const Add =({ onClose })  => {
  const [carrouselData, setCarrouselData] = useState({
    citation_CarrouselImg: '',
    auteur_CarrouselImg:'',
    images_CarrouselImg: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCarrouselData({
      ...carrouselData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setCarrouselData({
      ...carrouselData,
      images_CarrouselImg: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Créez un objet FormData pour envoyer le formulaire avec les fichiers
      const formData = new FormData();
      formData.append('citation_CarrouselImg', carrouselData.citation_CarrouselImg);
      formData.append('auteur_CarrouselImg', carrouselData.auteur_CarrouselImg);
      formData.append('images_CarrouselImg', carrouselData.images_CarrouselImg);

      // Effectuez une requête POST vers votre API
      await axios.post('/app/addCarrousel', formData);

      // Réinitialisez les champs du formulaire après l'envoi
      setCarrouselData({
        citation_CarrouselImg: '',
        auteur_CarrouselImg: '',
        images_CarrouselImg: null,
      });

      // Affichez un message de succès ou redirigez l'utilisateur
      alert('Image et citation ajouté avec succès!');
      onClose();
      // window.location.reload();
    } catch (error) {
      // Gérez les erreurs, par exemple, affichez un message d'erreur à l'utilisateur
      console.error('Erreur lors de l\'envoi du formulaire', error);
      alert('Erreur lors de l\'envoi du formulaire. Veuillez réessayer.');
    }
  };


  return (
    <div className='modalForm'>
      <div className='position-modalForm'>
        <div className='contenu-modalForm'>
        <div className="closeButton" onClick={onClose}><RxCross2 className='iconeCross'/></div>
          <h2>Formulaire d'ajout</h2>
          <form onSubmit={handleSubmit}>

            <textarea
              type="text"
              name="citation_CarrouselImg"
              value={carrouselData.citation_CarrouselImg}
              onChange={handleInputChange}
              placeholder="Citation :"
              required
            />

            <input
              name="auteur_CarrouselImg"
              value={carrouselData.auteur_CarrouselImg}
              onChange={handleInputChange}
              placeholder='Auteur de la citation :'
              required
            />


            <input type="file"
              name="images_CarrouselImg"
              accept="image/*"
              onChange={handleImageChange}
            />


            <button type="submit">Ajouter l'image et la citation</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add;