import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Psychosociologies = () => {
    const [Psychosociologies, setPsychosociologies] = useState([]);

    useEffect(() => {
        axios.get('/app/getPsychosociologies')
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setPsychosociologies(response.data)
                    // console.log(response.data);
                }
                else {
                    console.log("Les données ne sont pas reçues : ", response.data)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])
    return (
        <div className='blockQuiSuisJe'>
        <div className='legend'>Mes approches Psychosociologies</div>
        {Psychosociologies.map((Psychosociologies) => (
            <div className='blockDiplomes' key={Psychosociologies.id_Psychosociologie}>
                <span className='diplomes'>{Psychosociologies.approches}</span>
                <span className='texteDiplomes'>{Psychosociologies.texte}</span>
            </div>
        ))}
    </div>
    )
}

export default Psychosociologies