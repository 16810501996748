import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosBackspace } from 'react-icons/io';

function Retour() {
  return (
    <div className='blockButton' title='Retour à la page Dashboard'>
      <Link to="/Dashboard" aria-label='Retour à la page Dashboard'>
        <button aria-label='Retour'>
          <IoIosBackspace className='iconeLogOut' />
        </button>
      </Link>
    </div>
  );
}

export default Retour;