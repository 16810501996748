import React from "react";

import "./Mentions.css";

const Mentions = () => {
  return (
    <main className="Mentions">
      <h1> Mentions légales & politique de confidentialité</h1>

      <h2>1. Propriétaire du site :</h2>
      <div className="paragraphe">
        <p>Nom : Régine Szwabinski</p>
        <p>Statut : Micro-entreprise</p>
        <p>Adresse : 15 rue du Montceau 62440 HARNES </p>
        <p>
          Email : 
          <a href="mailto:regine-lille@hotmail.fr"> regine-lille@hotmail.fr</a>
        </p>
      </div>

      <h2>2. Créateur du site :</h2>
      <div className="paragraphe">
        <p>Nom : Delecroix Alexis</p>
        <p>Statut : Micro-entreprise</p>
        <p>Adresse : 21 GRAND rue 62144 Villers-au-Bois</p>
        <p>
          Email :
          <a href="mailto:d-alexis@hotmail.fr"> d-alexis@hotmail.fr</a>
        </p>
      </div>

      <h2>3. Hébergement :</h2>
      <div className="paragraphe" >
        <p>
          Le site L'envol By Régine est hébergé par LWS, dont le siège social
          est situé 10 Rue de Penthièvre 75008 Paris.
        </p>
      </div>

      <h2>4. Responsabilité :</h2>
      <div className="paragraphe">
        <p>
          Les informations fournies sur ce site sont la responsabilité du
          propriétaire et du créateur du site. Ils s'efforcent de fournir des
          informations exactes et à jour, mais ne peuvent garantir l'exactitude,
          l'exhaustivité ou la pertinence des informations fournies.
        </p>
      </div>

      <h2>5. Propriété intellectuelle :</h2>
      <div className="paragraphe">
        <p>
          Tous les contenus présents sur ce site (textes, images, vidéos, etc.)
          sont la propriété intellectuelle du créateur et du propriétaire du
          site, et sont protégés par les lois relatives aux droits d'auteur et à
          la propriété intellectuelle.
        </p>
      </div>

      <h2>6. Protection des données personnelles :</h2>
      <div className="paragraphe">
        <p>
          Les données personnelles collectées sur ce site sont utilisées
          uniquement dans le cadre de la relation commerciale avec les
          utilisateurs. Elles ne sont pas vendues, louées ou communiquées à des
          tiers.
        </p>
      </div>

      <h2>7. Contact :</h2>
      <div className="paragraphe">
        <p>
          Pour toute question ou réclamation concernant ce site, vous pouvez
          contacter le propriétaire ou le créateur à l'adresse email indiquée
          ci-dessus.
        </p>
      </div>
    </main>
  );
};

export default Mentions;
