import { useState, useEffect } from "react";
import axios from "axios";

import "../style/accueil.css";
import NavigationPrivate from "../components/NavigationPrivate";
import Slider from "../../../components/Accueil/slider/Slider";

import Add from "../components/form/Carrousel/Add";
import Update from "../components/form/Carrousel/Update";
import Delete from "../components/form/Carrousel/Delete";

import UpdatePresentation from "../components/form/Accueil/UpdatePresentation";
import UpdatePresentationDeux from "../components/form/Accueil/UpdatePresentationDeux";

import AddInterrogation from "../components/form/Accueil/AddInterrogation";
import UpdateInterrogation from "../components/form/Accueil/UpdateInterrogation";
import DeleteInterrogation from "../components/form/Accueil/DeleteInterrogation";

import AddObjectif from "../components/form/Accueil/AddObjectif";
import UpdateObjectif from "../components/form/Accueil/UpdateObjectif";
import DeleteObjectif from "../components/form/Accueil/DeleteObjectif";

const Accueil = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/app/getPresentation")
      .then((response) => {
        if (Array.isArray(response.data)) {
          setData(response.data);
          // console.log(response.data);
        } else {
          console.log("Les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [dataDeux, setDataDeux] = useState([]);

  useEffect(() => {
    axios
      .get("/app/getPresentationDeux")
      .then((response) => {
        if (Array.isArray(response.data)) {
          setDataDeux(response.data);
          // console.log(response.data);
        } else {
          console.log("Les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [liste, setListe] = useState([]);

  useEffect(() => {
    axios
      .get("/app/getListePresentation")
      .then((response) => {
        if (Array.isArray(response.data)) {
          setListe(response.data);
          // console.log(response.data);
        } else {
          console.log("les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [objectifs, setObjectifs] = useState([]);

  useEffect(() => {
    axios
      .get("/app/getObjectifs")
      .then((response) => {
        if (Array.isArray(response.data)) {
          setObjectifs(response.data);
          // console.log(response.data);
        } else {
          console.log("les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [showAddForm, setShowAddForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);

  const [showUpdatePresentationForm, setShowUpdatePresentationForm] =
    useState(false);
  const [showUpdatePresentationDeuxForm, setShowUpdatePresentationDeuxForm] =
    useState(false);

  const [showAddInterrogationForm, setShowAddInterrogationForm] =
    useState(false);
  const [showUpdateInterrogationForm, setShowUpdateInterrogationForm] =
    useState(false);
  const [showDeleteInterrogationForm, setShowDeleteInterrogationForm] =
    useState(false);

  const [showAddObjectifForm, setShowAddObjectifForm] = useState(false);
  const [showUpdateObjectifForm, setShowUpdateObjectifForm] = useState(false);
  const [showDeleteObjectifForm, setShowDeleteObjectifForm] = useState(false);

  const handleAddClick = () => setShowAddForm(true);
  const handleCloseAdd = () => setShowAddForm(false);

  const handleUpdateClick = () => setShowUpdateForm(true);
  const handleCloseUpdate = () => setShowUpdateForm(false);

  const handleDeleteClick = () => setShowDeleteForm(true);
  const handleCloseDelete = () => setShowDeleteForm(false);

  const handleUpdatePresentation = () => setShowUpdatePresentationForm(true);
  const handleCloseUpdatePresentation = () =>
    setShowUpdatePresentationForm(false);

  const handleUpdatePresentationDeux = () =>
    setShowUpdatePresentationDeuxForm(true);
  const handleCloseUpdatePresentationDeux = () =>
    setShowUpdatePresentationDeuxForm(false);

  const handleAddInterrogation = () => setShowAddInterrogationForm(true);
  const handleCloseAddInterrogation = () => setShowAddInterrogationForm(false);

  const [selectedInterrogationId, setSelectedInterrogationId] = useState(null);

  const handleUpdateInterrogation = (id_listePresentation) => {
    setSelectedInterrogationId(id_listePresentation);
    setShowUpdateInterrogationForm(true);
  };
  const handleCloseUpdateInterrogation = () => {
    setSelectedInterrogationId(null);
    setShowUpdateInterrogationForm(false);
  };

  const handleDeleteInterrogation = (id_listePresentation) => {
    setSelectedInterrogationId(id_listePresentation);
    setShowDeleteInterrogationForm(true);
  };
  const handleCloseDeleteInterrogation = () => {
    setSelectedInterrogationId(null);
    setShowDeleteInterrogationForm(false);
  };

  const handleAddObjectif = () => setShowAddObjectifForm(true);
  const handleCloseAddObjectif = () => setShowAddObjectifForm(false);

  const [selectedObjectifId, setSelectedObjectifId] = useState(null);

  const handleUpdateObjectif = (id_Objectifs) => {
    setSelectedObjectifId(id_Objectifs);
    setShowUpdateObjectifForm(true);
  };
  const handleCloseUpdateObjectif = () => {
    setSelectedObjectifId(null);
    setShowUpdateObjectifForm(false);
  };

  const handleDeleteObjectif = (id_Objectifs) => {
    setSelectedObjectifId(id_Objectifs);
    setShowDeleteObjectifForm(true);
  };
  const handleCloseDeleteObjectif = () => {
    setSelectedObjectifId(null);
    setShowDeleteObjectifForm(false);
  };

  return (
    <section className="accueilCrud">
      {showAddForm && <Add onClose={handleCloseAdd} />}
      {showUpdateForm && <Update onClose={handleCloseUpdate} />}
      {showDeleteForm && <Delete onClose={handleCloseDelete} />}
      {showUpdatePresentationForm && (
        <UpdatePresentation onClose={handleCloseUpdatePresentation} />
      )}
      {showUpdatePresentationDeuxForm && (
        <UpdatePresentationDeux onClose={handleCloseUpdatePresentationDeux} />
      )}
      {showAddInterrogationForm && (
        <AddInterrogation onClose={handleCloseAddInterrogation} />
      )}
      {showUpdateInterrogationForm && (
        <UpdateInterrogation
          onClose={handleCloseUpdateInterrogation}
          id_listePresentation={selectedInterrogationId}
        />
      )}
      {showDeleteInterrogationForm && (
        <DeleteInterrogation
          onClose={handleCloseDeleteInterrogation}
          id_listePresentation={selectedInterrogationId}
        />
      )}
      {showAddObjectifForm && <AddObjectif onClose={handleCloseAddObjectif} />}
      {showUpdateObjectifForm && (
        <UpdateObjectif
          onClose={handleCloseUpdateObjectif}
          id_Objectifs={selectedObjectifId}
        />
      )}
      {showDeleteObjectifForm && (
        <DeleteObjectif
          onClose={handleCloseDeleteObjectif}
          id_Objectifs={selectedObjectifId}
        />
      )}

      <NavigationPrivate />
      <h1>Gestion de la page d'accueil</h1>

      <div className="blockExplication">
        <div>
          <p>
            Bienvenue sur votre tableau de bord de la page d'accueil. Cette
            interface vous offre la possibilité de mettre à jour le contenu de
            votre site web en toute simplicité. Vous avez le contrôle total sur
            la personnalisation de votre page d'accueil, comme la possibilité de
            modifiez, ajoutez ou supprimez des images dans votre carrousel pour
            captiver vos visiteurs.
          </p>
          <p>
            Personnalisez les photos et textes de vos présentations pour
            refléter au mieux votre identité.
          </p>
          <p>
            Gérez les réponses à la question "Pourquoi me consulter ?" en
            ajoutant, modifiant ou supprimant des éléments, et définissez ou
            ajustez vos objectifs à accomplir en toute facilité.
          </p>
        </div>
      </div>

      <div className="blockCarrousel">
        <p id="titre">Personnalisation du carrousel</p>
        <div className="block-btn">
          <button onClick={handleAddClick}>Ajouter</button>
          <button onClick={handleUpdateClick}>Modifier</button>
          <button onClick={handleDeleteClick}>Supprimer</button>
        </div>

        <div className="conteneurCarrousel">
          <Slider showID={true} />
        </div>
      </div>

      <div className="conteneurPresentation">
        <p>Modification des presentations</p>
        {data.map((data) => (
          <div key={data.id_presentation} className="data-presentation">
            <div className="conteneurBtn">
              <button onClick={handleUpdatePresentation}>Modifier</button>
            </div>

            <span className="dataTitre">{data.titre_presentation}</span>
            <div className="conteneurData">
              <p>{data.texte_presentation}</p>
              <img
                src={`/images/Accueil/${data.image_presentation}`}
                alt="Regine"
              />
            </div>
          </div>
        ))}

        {dataDeux.map((data) => (
          <div key={data.id_Presentationdeux} className="data-presentation">
            <div className="conteneurBtn">
              <button onClick={handleUpdatePresentationDeux}>Modifier</button>
            </div>

            <span className="dataTitre">{data.titre_Presentationdeux}</span>
            <span className="dataSousTitre">
              {data.sousTitre_Presentationdeux}
            </span>
            <div className="conteneurData">
              <p>{data.texte_Presentationdeux}</p>
              <img
                src={`/images/Accueil/${data.images_Presentationdeux}`}
                alt="Femme qui médite"
              />
            </div>
          </div>
        ))}
      </div>

      <div className="conteneurIntAndObj">
        <span className="dataTitre">
          Personnalisation des interrogations et objectifs
        </span>
        <p id="titreInt">
          Ici vous avez la possibilité d'ajouté, de modifier et supprimer une
          interrogation, et un objectif qui sont présents sur votre page
          d'accueil
        </p>

        <div className="conteneurInterrogation">
          <div className="conteneurbtnInt">
            <button className="btnInt" onClick={handleAddInterrogation}>
              Ajouter une interrogation
            </button>
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Interrogation</th>
                  <th>Modifier</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {liste.map((interrogation) => (
                  <tr
                    className="conteneurListeInterrogation"
                    key={interrogation.id_listePresentation}
                  >
                    <td>{interrogation.questions_listePresentation}</td>
                    <td>
                      <button
                        onClick={() =>
                          handleUpdateInterrogation(
                            interrogation.id_listePresentation
                          )
                        }
                      >
                        Modifier
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() =>
                          handleDeleteInterrogation(
                            interrogation.id_listePresentation
                          )
                        }
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="conteneurInterrogation">
          <div className="conteneurbtnInt">
            <button className="btnInt" onClick={handleAddObjectif}>
              Ajouter un objectif
            </button>
          </div>
          <div className="table">
  <table>
    <thead>
      <tr>
        <th>Objectif</th>
        <th>Modifier</th>
        <th>Supprimer</th>
      </tr>
    </thead>
    <tbody>
      {objectifs.map((objectif) => (
        <tr className="conteneurListeInterrogation" key={objectif.id_Objectifs}>
          <td>{objectif.texte_Objectifs}</td>
          <td>
            <button onClick={() => handleUpdateObjectif(objectif.id_Objectifs)}>
              Modifier
            </button>
          </td>
          <td>
            <button onClick={() => handleDeleteObjectif(objectif.id_Objectifs)}>
              Supprimer
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
        </div>
      </div>
    </section>
  );
};

export default Accueil;
