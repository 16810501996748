import React, { useState } from 'react';
import axios from 'axios';
import "../../../style/modal.css"
import { RxCross2 } from "react-icons/rx";


const AddPsychosociale = ({ onClose }) => {
  // État local pour stocker les données de l'interrogation
  const [psychosocialeData, setPsychosocialeData] = useState({
    approches: '',
    texte: '',
  });

  // Gestion des changements dans les champs du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Mise à jour de l'état avec les nouvelles données du formulaire
    setPsychosocialeData({
      ...psychosocialeData,
      [name]: value,
    });
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Envoi de la requête POST vers l'API avec les données de l'interrogation
      await axios.post('/app/insertPsychosociales', psychosocialeData);

      // Réinitialisation des données du formulaire après l'envoi réussi
      setPsychosocialeData({
        approches: '',
        texte: ''
      });

      // Affichage d'une alerte pour informer l'utilisateur du succès
      alert('Approche Psychosociale ajouté avec succès');

      // Fermeture du modal
      onClose();

      // Rechargement de la page pour refléter les mises à jour
      // window.location.reload();
    } catch (error) {
      // Gestion des erreurs en cas d'échec de l'envoi du formulaire
      console.error('Erreur lors de l\'envoi du formulaire', error);
      alert('Erreur lors de l\'envoi du formulaire. Veuillez réessayer.');
    }
  };

  return (
    <div className='modalForm'>
      <div className='position-modalForm'>
        <div className='contenu-modalForm'>
          <div className="closeButton" onClick={onClose}><RxCross2 className='iconeCross' /></div>
          <h2>Formulaire d'ajout</h2>
          <form onSubmit={handleSubmit}>
            <input
              name="approches"
              onChange={handleInputChange}
              placeholder={`Nom de l'approche Psychosociale`}
              required
            />

            <input
              name="texte"
              onChange={handleInputChange}
              placeholder='Texte '
              required
            />
            <button type="submit">Ajouter une nouvelle approche Psychosociale</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPsychosociale;
