import React, { useState, useEffect } from "react";
import axios from "axios";
import "../style/quiSuisJe.css";
import NavigationPrivate from "../components/NavigationPrivate";

import PresentationQuiSuisje from "../../../components/QuiSuisJe/PresentationQuiSuisje";
import UpdatePresentation from "../components/form/QuiSuisJe/UpdatePresentation";
import AddDiplomes from "../components/form/QuiSuisJe/AddDiplomes";
import UpdateDiplomes from "../components/form/QuiSuisJe/UpdateDiplomes";
import DeleteDiplomes from "../components/form/QuiSuisJe/DeleteDiplomes";
import AddPsychosociale from "../components/form/QuiSuisJe/AddPsychosociale";
import UpdatePsychosociale from "../components/form/QuiSuisJe/UpdatePsychosociale";
import DeletePsychosociale from "../components/form/QuiSuisJe/DeletePsychosociale";
import AddPsychosociologie from "../components/form/QuiSuisJe/AddPsychosociologie";
import UpdatePsychosociologie from "../components/form/QuiSuisJe/UpdatePsychosociologie";
import DeletePsychosociologie from "../components/form/QuiSuisJe/DeletePsychosociologie";

const QuiSuisJe = () => {
  // Modification Presentation
  const [showUpdateForm, setShowUpdateForm] = useState(false);

  const handleBtnUpdate = () => setShowUpdateForm(true);
  const handleCloseForm = () => setShowUpdateForm(false);
  // Fin modification Presentation

  // CRUD Diplomes
  const [diplomes, setDiplomes] = useState([]);

  useEffect(() => {
    axios
      .get("/app/getDiplomes")
      .then((response) => {
        if (Array.isArray(response.data)) {
          setDiplomes(response.data);
          // console.log(response.data);
        } else {
          console.log("Les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [psychosociales, setPsychosociales] = useState([]);

  useEffect(() => {
      axios.get('/app/getPsychosociales')
          .then((response) => {
              if (Array.isArray(response.data)) {
                  setPsychosociales(response.data)
                  // console.log(response.data);
              }
              else {
                  console.log("Les données ne sont pas reçues :", response.data);
              }
          })
          .catch((err) => {
              console.log(err);
          })
  }, [])

  const [psychosociologies, setPsychosociologies] = useState([]);

  useEffect(() => {
      axios.get('/app/getPsychosociologies')
          .then((response) => {
              if (Array.isArray(response.data)) {
                  setPsychosociologies(response.data)
                  // console.log(response.data);
              }
              else {
                  console.log("Les données ne sont pas reçues : ", response.data)
              }
          })
          .catch((err) => {
              console.log(err);
          })
  }, [])


  // Diplome
  const [showAddDiplome, setShowAddForm] = useState(false)
  
  const handleOpenAddDiplomeClick = () => setShowAddForm(true)
  const handleCloseAddDiplomeClick = () => setShowAddForm(false)

  const [selectedIdDiplome, setSelectedIdDiplome] = useState(null)
  const [showUpdateDiplome, setShowUpdateDiplome] = useState(false)

  const handleOpenUpdateDiplomeClick = (id_Diplomes) => {
    setSelectedIdDiplome(id_Diplomes)
    setShowUpdateDiplome(true)
  }

  const handleCloseUpdateDiplomeClick = () => {
    setSelectedIdDiplome(null)
    setShowUpdateDiplome(false)
  }

  const [showDeleteDiplome, setShowDeleteDiplome] = useState(false)

  const handleOpenDeleteDiplomeClick = (id_Diplomes) => {
    setSelectedIdDiplome(id_Diplomes)
    setShowDeleteDiplome(true)
  }

  const handleCloseDeleteDiplomeClick = () => {
    setSelectedIdDiplome(null)
    setShowDeleteDiplome(false)
  }

  // Fin diplome

  // Psychosociale
  const [showAddPsychosociale, setShowAddPsychosociale] = useState(false)

  const handleOpenAddPsychosociale = () => setShowAddPsychosociale(true)
  const handleCloseAddPsychosociale = () => setShowAddPsychosociale(false)

  const [selectedIdPsychosociale, setSelectedIdPsychosociale] = useState(null)
  const [showUpdatePsychosociale, setShowUpdatePsychosociale] = useState(false)

  const handleOpenUpdatePsychosocialeClick = (id_Psychosociales) => {
    setSelectedIdPsychosociale(id_Psychosociales)
    setShowUpdatePsychosociale(true)
  }

  const handleCloseUpdatePsychosocialeClick = () => {
    setSelectedIdPsychosociale(null)
    setShowUpdatePsychosociale(false)
  }

  const [showDeletePsychosociale, setShowDeletePsychosociale] = useState(false)

  const handleOpenDeletePsychosocialeClick = (id_Psychosociales) => {
    setSelectedIdPsychosociale(id_Psychosociales)
    setShowDeletePsychosociale(true)
  }

  const handleCloseDeletePsychosocialeClick = () => {
    setSelectedIdPsychosociale(null)
    setShowDeletePsychosociale(false)
  }
  // Fin psychosociale

  // Psychosociologie

  const [showAddPsychosociologie, setShowAddPsychosociologie] = useState(false)

  const handleOpenAddPsychosociologie = () => setShowAddPsychosociologie(true)
  const handleCloseAddPsychosociologie = () => setShowAddPsychosociologie(false)

  const [selectedIdPsychosociologie, setSelectedIdPsychosociologie] = useState(null)
  const [showUpdatePsychosociologie, setShowUpdatePsychosociologie] = useState(false)

  const handleOpenUpdatePsychosociologieClick = (id_Psychosociologie) => {
    setSelectedIdPsychosociologie(id_Psychosociologie)
    setShowUpdatePsychosociologie(true)
  }

  const handleCloseUpdatePsychosociologieClick = () => {
    setSelectedIdPsychosociologie(null)
    setShowUpdatePsychosociologie(false)
  }

  const [showDeletePsychosociologie, setShowDeletePsychosociologie] = useState(false)

  const handleOpenDeletePsychosociologieClick = (id_Psychosociologie) => {
    setSelectedIdPsychosociologie(id_Psychosociologie)
    setShowDeletePsychosociologie(true)
  }

  const handleCloseDeletePsychosociologieClick = () => {
    setSelectedIdPsychosociologie(null)
    setShowDeletePsychosociologie(false)
  }

  return (
    <>
      {showUpdateForm && <UpdatePresentation onClose={handleCloseForm} />}
      {showAddDiplome && <AddDiplomes onClose={handleCloseAddDiplomeClick} />}
      {showUpdateDiplome && <UpdateDiplomes onClose={handleCloseUpdateDiplomeClick} id_Diplomes={selectedIdDiplome}/>}
      {showDeleteDiplome && <DeleteDiplomes onClose={handleCloseDeleteDiplomeClick} id_Diplomes={selectedIdDiplome} />}
      {showAddPsychosociale && <AddPsychosociale onClose={handleCloseAddPsychosociale} />}
      {showUpdatePsychosociale && <UpdatePsychosociale onClose={handleCloseUpdatePsychosocialeClick} id_Psychosociales={selectedIdPsychosociale} />}
      {showDeletePsychosociale && <DeletePsychosociale onClose={handleCloseDeletePsychosocialeClick} id_Psychosociales={selectedIdPsychosociale} />}
      {showAddPsychosociologie && <AddPsychosociologie onClose={handleCloseAddPsychosociologie} />}
      {showUpdatePsychosociologie && <UpdatePsychosociologie onClose={handleCloseUpdatePsychosociologieClick} id_Psychosociologie={selectedIdPsychosociologie} />}
      {showDeletePsychosociologie && <DeletePsychosociologie onClose={handleCloseDeletePsychosociologieClick} id_Psychosociologie={selectedIdPsychosociologie} />}
      <section className="quiSuisJeCrud">
        <NavigationPrivate />
        <h2>Gestion de la page Qui suis je</h2>
        <p>Modification de la presentation page Qui Suis Je</p>
        <div className="blockPresentationCrud">
          <div className="positionBtn">
            <button onClick={handleBtnUpdate}>Modifier la présentation</button>
          </div>

          <PresentationQuiSuisje />
        </div>

        <div className="block">

          <p>
            Ici vous avez la possibilité d'ajouter, de modifier et de supprimer vos
            diplômes et vos approches.
          </p>

          <button onClick={handleOpenAddDiplomeClick} className="newData">Ajouter un nouveau diplôme</button>

          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>Diplôme</th>
                  <th>Texte</th>
                  <th>Modifier</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {diplomes.map((diplome) => (
                  <tr className="conteneurDiplome" key={diplome.id_Diplomes}>
                    <td>{diplome.diplomes}</td>
                    <td>{diplome.texte}</td>
                    <td>
                      <button onClick={() => handleOpenUpdateDiplomeClick(diplome.id_Diplomes)}>Modifier</button>
                    </td>
                    <td>
                      <button onClick={() => handleOpenDeleteDiplomeClick(diplome.id_Diplomes)}>Supprimer</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button onClick={handleOpenAddPsychosociale} className="newData">Ajouter une nouvelle approche Psychosociale</button>
          <div className="table">
          <table>
              <thead>
                <tr>
                  <th>Approches Psychosociales</th>
                  <th>Texte</th>
                  <th>Modifier</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {psychosociales.map((psychosociales) => (
                  <tr className="conteneurDiplome" key={psychosociales.id_Psychosociales}>
                    <td>{psychosociales.approches}</td>
                    <td>{psychosociales.texte}</td>
                    <td>
                      <button onClick={() => handleOpenUpdatePsychosocialeClick(psychosociales.id_Psychosociales)}>Modifier</button>
                    </td>
                    <td>
                      <button onClick={() => handleOpenDeletePsychosocialeClick(psychosociales.id_Psychosociales)}>Supprimer</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <button onClick={handleOpenAddPsychosociologie} className="newData">Ajouter une nouvelle approche Psychosociologie</button>
          <div className="table">
          <table>
              <thead>
                <tr>
                  <th>Approches Psychosociologie</th>
                  <th>Texte</th>
                  <th>Modifier</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {psychosociologies.map((psychosociologies) => (
                  <tr className="conteneurDiplome" key={psychosociologies.id_Psychosociologie}>
                    <td>{psychosociologies.approches}</td>
                    <td>{psychosociologies.texte}</td>
                    <td>
                      <button onClick={() => handleOpenUpdatePsychosociologieClick(psychosociologies.id_Psychosociologie)}>Modifier</button>
                    </td>
                    <td>
                      <button onClick={() => handleOpenDeletePsychosociologieClick(psychosociologies.id_Psychosociologie)}>Supprimer</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

      </section>
    </>
  );
};

export default QuiSuisJe;
