import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';

const UpdateDiplomes = ({ onClose, id_Diplomes }) => {
    // console.log(id_Diplomes);
    const [diplomeData, setDiplomeData] = useState({
        id_Diplomes: '',
        diplomes: '',
        texte: '',
    });

    useEffect(() => {
        if (id_Diplomes) {
            axios
                .get(`/app/getDiplomeById/${id_Diplomes}`)
                .then((response) => {
                    // console.log(response);
                    // console.log(response.data);
                    setDiplomeData((prevData) => ({
                        ...prevData,
                        id_Diplomes: response.data.Data.id_Diplomes,
                        diplomes: response.data.Data.diplomes,
                        texte: response.data.Data.texte
                    }));
                })
                .catch((error) => {
                    console.error('Erreur lors de la récupération des données', error);
                });
        }
    }, [id_Diplomes, setDiplomeData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setDiplomeData({
            ...diplomeData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.put(`/app/updateDiplomes/${diplomeData.id_Diplomes}`, diplomeData);

            alert('Diplôme modifiée avec succès!');

            onClose();
            // window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la modification du Diplôme', error);
        }
    };

    return (
        <div className="modalForm">
            <div className="position-modalForm">
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}>
                        <RxCross2 className="iconeCross" />
                    </div>
                    <h2>Modifier les informations du diplôme</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="diplomes"
                            value={diplomeData.diplomes || ''}
                            onChange={handleInputChange}
                            placeholder="Nom du diplôme"
                        />

                        <input
                            type="text"
                            name="texte"
                            value={diplomeData.texte || ''}
                            onChange={handleInputChange}
                            placeholder="texte"
                        />
                        <button type="submit">Modifier</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default UpdateDiplomes;
