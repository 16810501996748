// Importations nécessaires
import React, { useEffect, useState } from "react";
import axios from "axios";
import NavigationPrivate from '../components/NavigationPrivate'
import Add from "../components/form/Actualites/Add";
import Delete from "../components/form/Actualites/Delete";
import Update from "../components/form/Actualites/Update";
import '../style/actualites.css'

const Actualites = () => {
  const [data, setData] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);

  // Fonction pour récupérer les articles lors du chargement de la page
  useEffect(() => {
    axios.get('/app/getArticlesCrud')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setData(response.data);
          // console.log(response.data);
        } else {
          console.log("Les données ne sont pas reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }, []);

  // Fonctions pour gérer l'affichage des formulaires
  const handleAddClick = () => setShowAddForm(true);
  const handleCloseAdd = () => setShowAddForm(false);

  const handleDeleteClick = () => setShowDeleteForm(true);
  const handleCloseDelete = () => setShowDeleteForm(false);

  const handleUpdateClick = () => setShowUpdateForm(true);
  const handleCloseUpdate = () => setShowUpdateForm(false);

  return (
    <section className="Actualites">

      {showAddForm && <Add onClose={handleCloseAdd} />}
      {showDeleteForm && <Delete onClose={handleCloseDelete} />}
      {showUpdateForm && <Update onClose={handleCloseUpdate} />}
      <NavigationPrivate />

      <h1>Gestion de la page Actualites</h1>

      <div className="Gestion">
        <button onClick={handleAddClick}>Ajouter</button>
        <button onClick={handleUpdateClick}>Modifier</button>
        <button onClick={handleDeleteClick}>Supprimer</button>
      </div>

      <div className="blockArticles">
        {data.map((articles) => (
          <div className="Articles" key={articles.id_Articles}>
            <div className="idArticles">
              <p>Numéro de l'article : <span>{articles.id_Articles}</span></p>
            </div>
            <p ><span id="titre">Titre de l'article :</span> {articles.titre_Articles}</p>
            <div className="conteneurImg">
              <img src={`/images/articles/${articles.image_Articles}`} alt="" />
            </div>
            <div className="conteneurParagraphe">
              <div>
                <span>Premier Paragraphe : </span>
                <p>{articles.texte_Articles}</p>
              </div>
              <div>
                <span>2 ème Paragraphe : </span>
                <p>{articles.texte2_Articles}</p>
              </div>
              <div>
                <span>3 ème Paragraphe : </span>
                <p>{articles.texte3_Articles}</p>
              </div>
            </div>
            <div className="conteneurInformation">
              <div>
                <p><span id="titre">Nom de l'auteur : </span>{articles.auteur_Articles}</p>
              </div>
              <p> <span id="titre">Date de publication : </span>{articles.date_Articles}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Actualites;