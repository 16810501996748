import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Seances1 from '../../components/seances/seances1';
import Seances2 from '../../components/seances/seances2';
import Seances4 from '../../components/seances/seances4';
import Seances3 from '../../components/seances/seances3';
import Seances5 from '../../components/seances/Seances5';

import './seances.css';

const Seances = () => {
  const { id } = useParams(); // Récupérer l'ID du service depuis l'URL

  // Fonction pour return le composant de détail du service en fonction de l'ID
  const renderSeanceDetail = () => {
    if (id === "1") {
      return <Seances1 />;
    } else if (id === "2") {
      return <Seances2 />;
    } else if (id === "3") {
      return <Seances3 />;
    } else if (id === "4") {
      return <Seances4 />;
    } else if (id === "5") {
      return <Seances5 />;
    } else {
      return <div></div>; // Gérer le cas où l'ID n'est pas trouvé
    }
  };

  return (
    <section className='seances'>
      <h1>Séances & Tarifs</h1>
      <div className="service-buttons">
        <Link to="/seances/1"><button className={id === "1" ? 'active' : ''}>Hypnose Ericksonienne</button></Link>
        <Link to="/seances/2"><button className={id === "2" ? 'active' : ''}>Magnétisme et Energie</button></Link>
        <Link to="/seances/3"><button className={id === "3" ? 'active' : ''}>Méditation Sonore</button></Link>
        <Link to="/seances/4"><button className={id === "4" ? 'active' : ''}>Séance de Coaching</button></Link>
        <Link to="/seances/5"><button className={id === "5" ? 'active' : ''}>Annales Akashiques</button></Link>

      </div>
      <div className="seance-details">
        {renderSeanceDetail()}
      </div>
    </section>
  );
}

export default Seances;

