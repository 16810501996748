import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';

const UpdatePresentation = ({ onClose }) => {
    const presentationId = 1;

    // État local pour stocker les données de la présentation
    const [presentationData, setPresentationData] = useState({
        titre_presentation: '',
        texte_presentation: '',
        image_presentation: '',
    });

    // État local pour stocker la nouvelle image sélectionnée par l'utilisateur
    const [newImage, setNewImage] = useState(null);

    // Fonction pour récupérer les données de la présentation depuis le serveur
    const getPresentation = async () => {
        try {
            // Requête GET pour obtenir les données de la présentation par ID
            const response = await axios.get(
                `/app/getPresentationById/${presentationId}`
            );

            // Met à jour l'état local avec les données récupérées
            if (response.data.PresentationData) {
                setPresentationData(response.data.PresentationData);
                console.log('Nouvelles données récupérées :', response.data.PresentationData);
            } else {
                console.log('Aucune donnée reçue');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    // Effet useEffect pour appeler la fonction getPresentation une fois au chargement du composant
    useEffect(() => {
        getPresentation();
    }, []);

    // Fonction de gestion de la soumission du formulaire de modification
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Vérifie si l'ID de la présentation est manquant
        if (!presentationId) {
            console.error('ID de l\'article manquant');
            return;
        }

        try {
            // Initialise FormData pour gérer l'envoi de fichiers (images)
            const formData = new FormData();

            // Si une nouvelle image est sélectionnée, l'ajoute à FormData et effectue le téléchargement
            if (newImage) {
                formData.append('image_presentation', newImage);
                const responseImage = await axios.post(
                    '/app/uploadPresentation',
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                // Obtient le nom de la nouvelle image téléchargée
                const newImageName = responseImage.data.imageName;

                // Envoie les données de la présentation avec le nom de la nouvelle image à l'endpoint d'update
                const responseArticle = await axios.put(
                    `/app/updatePresentation/${presentationId}`,
                    {
                        titre_presentation: presentationData.titre_presentation,
                        texte_presentation: presentationData.texte_presentation,
                        image_presentation: newImageName,
                    }
                );

                // Gère la réponse de la mise à jour
                if (responseArticle.data.PresentationData) {
                    console.log(responseArticle.data.message);
                    console.log('Updated Article Data:', responseArticle.data.PresentationData);

                    // Ajoutez ici d'autres actions en cas de succès
                } else {
                    console.log('Aucune donnée d\'article mise à jour reçue');
                }
            } else {
                // Si aucune nouvelle image n'est sélectionnée, envoie la requête sans l'image
                const responseArticle = await axios.put(
                    `/app/updatePresentation/${presentationId}`,
                    {
                        titre_presentation: presentationData.titre_presentation,
                        texte_presentation: presentationData.texte_presentation,
                        image_presentation: presentationData.image_presentation,
                    }
                );

                // Gère la réponse de la mise à jour
                if (responseArticle.data.PresentationData) {
                    console.log(responseArticle.data.message);
                    console.log('Updated Article Data:', responseArticle.data.PresentationData);

                    // Ajoutez ici d'autres actions en cas de succès
                } else {
                    console.log('Aucune donnée d\'article mise à jour reçue');
                }
            }

            // Ferme la modal et recharge la page
            onClose();
            // window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'article', error);
        }
    };

    // Fonction pour gérer le changement de l'image sélectionnée
    const handleImageChange = (e) => {
        setNewImage(e.target.files[0]);
    };

    // Rendu JSX du composant
    return (
        <div className="modalForm">
            <div className="position-modalForm">
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}>
                        <RxCross2 className="iconeCross" />
                    </div>
                    <h2>Modifier la présentation</h2>
                    <form encType="multipart/form-data" onSubmit={handleSubmit}>

                        {/* Champ de saisie pour le titre de la présentation */}
                        <input
                            type="text"
                            value={presentationData.titre_presentation}
                            onChange={(e) =>
                                setPresentationData({
                                    ...presentationData,
                                    titre_presentation: e.target.value,
                                })
                            }
                        />

                        {/* Champ de saisie pour le texte de la présentation */}
                        <textarea
                            value={presentationData.texte_presentation}
                            onChange={(e) =>
                                setPresentationData({
                                    ...presentationData,
                                    texte_presentation: e.target.value,
                                })
                            }
                        />

                        {/* Champ de téléchargement pour l'image de la présentation */}
                        <input
                            type="file"
                            accept="image/*"
                            name="image_presentation"
                            onChange={handleImageChange}
                        />

                        {/* Bouton de soumission du formulaire */}
                        <button type="submit">Modifier la présentation</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

// Exporte le composant pour une utilisation dans d'autres fichiers
export default UpdatePresentation;
