// Connexion.js

import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import './style/connexion.css';

const Connexion = ({ onLogin }) => {
  const [email_admin, setEmail_admin] = useState("");
  const [password_admin, setPassword_admin] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/app/auth/login', {
        email_admin,
        password_admin,
      });

      localStorage.setItem("token", response.data.token);

      onLogin(); // Appeler onLogin si la connexion est réussie
      navigate("/Dashboard"); // Naviguer vers la page protégée (Dashboard)
    } catch (err) {
      setError("Identifiants incorrects. Veuillez réessayer.");
    }
  };

  return (
    <section className="Connexion">
      <form>
        <h1>Connexion</h1>
        <div>
          <input
            type="email"
            id="email"
            value={email_admin}
            placeholder="Votre Email :"
            onChange={(e) => setEmail_admin(e.target.value)}
          />
        </div>
        <div>
          <input
            type="password"
            id="password"
            value={password_admin}
            placeholder="Mot de passe :"
            onChange={(e) => setPassword_admin(e.target.value)}
          />
        </div>
        <button type="submit" onClick={handleLogin}>
          Se connecter
        </button>
        {error && <p className="error">{error}</p>}
      </form>
      <p>( Cet espace de connexion est strictement réservé à l'administrateur du site. )</p>
    </section>
  );
};

export default Connexion;



