import React from 'react'
import Slider from '../../components/Accueil/slider/Slider'


import './Accueil.css'

import Presentation from '../../components/Accueil/presentation';
import PresentationDeux from '../../components/Accueil/PresentationDeux';
import Objectifs from '../../components/Accueil/Objectifs';

const Accueil = () => {
  return (
    <div>
      <Slider />
      <section className='accueil'>
        <Presentation />
        <PresentationDeux />
        <Objectifs />
      </section>
    </div>
  )
}

export default Accueil