import React, { useState } from 'react';
import axios from 'axios';

import "../../../style/modal.css"
import { RxCross2 } from "react-icons/rx";

const AddObjectif =({ onClose })  => {
  const [objectifData, setObjectifData] = useState({
    texte_Objectifs: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setObjectifData({
      ...objectifData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

  
      await axios.post('/app/insertObjectifs', objectifData);
  
      setObjectifData({
        texte_Objectifs: '',
      });
  
      alert('Objectif ajouté avec succès');
      onClose();
      // window.location.reload();
    } catch (error) {
      console.error('Erreur lors de l\'envoi du formulaire', error);
      alert('Erreur lors de l\'envoi du formulaire. Veuillez réessayer.');
    }
  };


  return (
    <div className='modalForm'>
      <div className='position-modalForm'>
        <div className='contenu-modalForm'>
        <div className="closeButton" onClick={onClose}><RxCross2 className='iconeCross'/></div>
          <h2>Formulaire d'ajout d'un objectif</h2>
          <form onSubmit={handleSubmit}>
            <input
              name="texte_Objectifs"
              onChange={handleInputChange}
              placeholder='Objectif :  '
              required
            />
            <button type="submit">Ajouter un objectif</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddObjectif;