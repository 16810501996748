import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BiSolidMessage } from "react-icons/bi";

const Message = () => {
  const [nouveauMessages, setNouveauMessages] = useState([]);

  useEffect(() => {
    // Mettre à jour le tableau de nouveaux messages au montage du composant
    updateNouveauMessages();
  }, []);

  const updateNouveauMessages = async () => {
    try {
      const response = await axios.get('/app/getNouveauMessage');
      if (Array.isArray(response.data)) {
        setNouveauMessages(response.data);
        // console.log(response.data);
      } else {
        console.log("Les données n'ont pas été reçues : ", response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Link to="/Messagerie">
      <div className="blockButton">
        <button title="Message">
          <BiSolidMessage className='iconeMessage' />
          <span>{nouveauMessages.length}</span>
        </button>
      </div>
    </Link>
  );
};

export default Message;

