import React from 'react'
import Deconnexion from './Deconnexion'
import Message from './Message'
import Retour from './Retour'

const NavigationPrivate = () => {
  return (
    <nav className='Navigation'>
        <div className='blockNavigation'>
        <Message/>
        <Retour/>
        <Deconnexion/>
    
    </div>
    </nav>
  )
}

export default NavigationPrivate