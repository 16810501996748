import React, { useState } from 'react';
import axios from 'axios';
import { RiCloseLine } from 'react-icons/ri';

const AddQuestions = ({ onClose }) => {
    const [questionData, setQuestionData] = useState({
        question_Questions: '',
        reponse_Questions: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setQuestionData({
            ...questionData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Envoyer les données au backend avec axios
            await axios.post('/app/insertQuestion', questionData);


            setQuestionData({
                question_Questions: '',
                reponse_Questions: '',
            })

            alert('Question ajouté avec succès!');
            
            onClose();
            // window.location.reload();
        } catch (error) {
            console.error('Erreur lors de l\'ajout de la question', error);
            // Ajouter ici la gestion des erreurs
        }
    };

    return (
        <div className="modalForm">
            <div className="position-modalForm">
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}>
                        <RiCloseLine className="iconeCross" />
                    </div>
                    <h2>Ajouter une question fréquente</h2>
                    <form onSubmit={handleSubmit}>

                        <input
                            type="text"
                            name="question_Questions"
                            value={questionData.question_Questions}
                            onChange={handleInputChange}
                            placeholder='Question fréquente'
                        />


                        <input
                            name="reponse_Questions"
                            value={questionData.reponse_Questions}
                            onChange={handleInputChange}
                            placeholder='Reponse'
                        />

                        <button type="submit">Ajouter</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddQuestions;
