import axios from 'axios';
import React, { useEffect, useState } from 'react'

const Seances2 = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const serviceId = 3;
  const etapeId = 2;
  const tarifsId = 2;

  useEffect(() => {
    axios.get(`/app/getPresentationService/${serviceId}`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setData(response.data)
          // console.log(response.data);
        } else {
          console.log("Les données n'ont pas été reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])


  useEffect(() => {
    axios.get(`/app/getEtapesService/${etapeId}`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setData2(response.data)
          // console.log(response.data);
        } else {
          console.log("Les données n'ont pas été reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  useEffect(() => {
    axios.get(`/app/getTarifsService/${tarifsId}`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setData3(response.data)
          // console.log(response.data);
        } else {
          console.log("Les données n'ont pas été reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])
  return (
    <>
      <article className='blockSeances'>
        {data.map((Presentation) => (
          <div key={Presentation.id_PresentationService}>
            <h2>{Presentation.titre_PresentationService}</h2>
            <div className='blockPresentation'>
              <p>{Presentation.texte_PresentationService}</p>
              <div className='blockImg'>
                <img src={`/images/Seances&tarifs/${Presentation.image_PresentationService}`} alt="" />
              </div>
            </div>
          </div>
        ))}
        <h3>Les principales étapes d'une séance de magnétisme</h3>
        {
          data2.map((etape) => (
            <div className='blockEtape' key={etape.id_Etapes}>
              <h4>Etape {etape.ordre_Etapes} : {etape.nom_etapes} </h4>
              <p>{etape.texte_etapes}</p>
            </div>
          ))
        }
        {
          data3.map((tarif) => (
            <div className='blockTarif' key={tarif.id_Tarifs}>

              <h5>{tarif.titre_Tarifs}</h5>
              <div className='blockInformation'>
                <p className='tarif'>{tarif.prix_Tarifs}</p>
                <p className='information'>{tarif.informations_Tarifs}</p>
              </div>
            </div>
          ))
        }
      </article>
    </>
  )
}

export default Seances2