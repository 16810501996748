import React from 'react'
import PresentationQuiSuisje from '../../components/QuiSuisJe/PresentationQuiSuisje'
import Diplomes from '../../components/QuiSuisJe/Diplomes'
import Psychosociologies from '../../components/QuiSuisJe/Psychosociologies'
import Psychosociales from '../../components/QuiSuisJe/Psychosociales'

import './QuiSuisJe.css'

const QuiSuisJe = () => {
  return (
    <section className='QuiSuisJe'>
        <PresentationQuiSuisje/>
        <Diplomes/>
        <Psychosociologies/>
        <Psychosociales/>
    </section>
  )
}

export default QuiSuisJe