import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BiSolidLogOut } from "react-icons/bi";

const Deconnexion = () => {
  const navigate = useNavigate();

  const handleDeconnexion = () => {
    localStorage.removeItem('token');
    // Rediriger l'utilisateur vers la page d'accueil
    navigate('/');
    // Actualiser la page pour refléter les changements (optionnel, en fonction de vos besoins)
    window.location.reload();
  };

  return (
    <div className="blockButton">
      <button onClick={handleDeconnexion} title="Deconnexion" ><BiSolidLogOut className='iconeLogOut' /></button>
    </div>
  );
};

export default Deconnexion;







