import React, { useState } from 'react';
import axios from 'axios';
import { RxCross2 } from "react-icons/rx";

const DeleteQuestions = ({ onClose, questionId }) => {

    console.log(questionId);


    const handleDelete = async () => {
        try {
            if (!questionId) {
                   console.log("Erreur d'id");
                   return
            }

            await axios.delete(`/app/deleteQuestion/${questionId}`)

            onClose();
            alert('Question supprimé avec succès')
            // window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la suppression de la question', error);

        }
    }


    return (
        <div className="modalForm">
            <div className='position-modalForm'>
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}><RxCross2 className='iconeCross' /></div>
                    <h2>Supprimer une question fréquente</h2>
                    <div className='conteneurDelete'>
                        <p>Etes vous sur de vouloir supprimer cette question ?</p>
                        <div className='conteneurBtnDelete'>
                        <button onClick={handleDelete}>Oui</button>
                        <button onClick={onClose}>Non</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteQuestions