import React from 'react';
import axios from 'axios';
import { RxCross2 } from "react-icons/rx";

const DeleteDiplomes = ({ onClose, id_Diplomes }) => {

    console.log(id_Diplomes);


    const handleDelete = async () => {
        try {
            if (!id_Diplomes) {
                   console.log("Erreur d'id");
                   return
            }

            await axios.delete(`/app/deleteDiplomes/${id_Diplomes}`)

            onClose();
            alert('Diplôme supprimée avec succès')
            // window.location.reload();
        } catch (error) {
            console.error('Erreur lors de la suppression', error);

        }
    }


    return (
        <div className="modalForm">
            <div className='position-modalForm'>
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}><RxCross2 className='iconeCross' /></div>
                    <h2>Supprimer ce diplôme</h2>
                    <div className='conteneurDelete'>
                        <p>Etes vous sur de vouloir supprimer ce diplôme ?</p>
                        <div className='conteneurBtnDelete'>
                        <button onClick={handleDelete}>Oui</button>
                        <button onClick={onClose}>Non</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteDiplomes