import { useEffect, useState } from "react";
import axios from 'axios';

const PresentationQuiSuisje = () => {

    const [presentation, setPresentation] = useState([]);

    useEffect(() => {
        axios.get('/app/getQuiSuisJe')
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setPresentation(response.data);
                    // console.log(response.data);
                }
                else {
                    console.log("Les données ne sont pas reçues : ", response.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <>
            {presentation.map((presentation) => (
                <div className="containerPresentation" key={presentation.id_Qui_suis_je}>
                    <h1>{presentation.titre_Qui_suis_je}</h1>
                    <div className="blockPresentation">
                        <div className="blockImage">
                            <img src={`/images/presentation/${presentation.images_Qui_suis_je}`} alt="Régine" />
                        </div>
                        <div className="blockTexte">
                            <p>{presentation.texte_Qui_suis_je}</p>
                        </div>
                    </div>
                </div>
            ))}

        </>
    )
}

export default PresentationQuiSuisje