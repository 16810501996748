import React, { useState } from 'react';
import axios from 'axios';

import "../../../style/modal.css"
import { RxCross2 } from "react-icons/rx";

const Add =({ onClose })  => {
  const [articleData, setArticleData] = useState({
    titre_Articles: '',
    texte_Articles: '',
    texte2_Articles: '',
    texte3_Articles: '',
    date_Articles: '',
    auteur_Articles: '',
    image_Articles: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setArticleData({
      ...articleData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setArticleData({
      ...articleData,
      image_Articles: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Créez un objet FormData pour envoyer le formulaire avec les fichiers
      const formData = new FormData();
      formData.append('titre_Articles', articleData.titre_Articles);
      formData.append('texte_Articles', articleData.texte_Articles);
      formData.append('texte2_Articles', articleData.texte2_Articles);
      formData.append('texte3_Articles', articleData.texte3_Articles);
      formData.append('date_Articles', articleData.date_Articles);
      formData.append('auteur_Articles', articleData.auteur_Articles);
      formData.append('image_Articles', articleData.image_Articles);

      // Effectuez une requête POST vers votre API
      await axios.post('/app/add', formData);

      // Réinitialisez les champs du formulaire après l'envoi
      setArticleData({
        titre_Articles: '',
        texte_Articles: '',
        texte2_Articles: '',
        texte3_Articles: '',
        date_Articles: '',
        auteur_Articles: '',
        image_Articles: null,
      });

      // Affichez un message de succès ou redirigez l'utilisateur
      alert('Article ajouté avec succès!');
      onClose();
      // window.location.reload();
    } catch (error) {
      // Gérez les erreurs, par exemple, affichez un message d'erreur à l'utilisateur
      console.error('Erreur lors de l\'envoi du formulaire', error);
      alert('Erreur lors de l\'envoi du formulaire. Veuillez réessayer.');
    }
  };


  return (
    <div className='modalForm'>
      <div className='position-modalForm'>
        <div className='contenu-modalForm'>
        <div className="closeButton" onClick={onClose}><RxCross2 className='iconeCross'/></div>
          <h2>Ajouter un Article</h2>
          <form onSubmit={handleSubmit}>

            <input
              type="text"
              name="titre_Articles"
              value={articleData.titre_Articles}
              onChange={handleInputChange}
              placeholder="Titre de l'article"
              required
            />

            <textarea
              name="texte_Articles"
              value={articleData.texte_Articles}
              onChange={handleInputChange}
              placeholder='Premier paragraphe'
              required
            />

            <textarea
              name="texte2_Articles"
              value={articleData.texte2_Articles}
              onChange={handleInputChange}
              placeholder='2 ème paragraphe'
              required
            />

            <textarea
              name="texte3_Articles"
              value={articleData.texte3_Articles}
              onChange={handleInputChange}
              placeholder='3 ème paragraphe'
              required
            />

            <input
              type="text"
              name="date_Articles"
              value={articleData.date_Articles}
              onChange={handleInputChange}
              placeholder="date de l'article"
              required
            />

            <input type="text"
              name="auteur_Articles"
              value={articleData.auteur_Articles}
              onChange={handleInputChange}
              placeholder="Auteur de l'article"
              required
            />

            <input type="file"
              name="image_Articles"
              accept="image/*"
              onChange={handleImageChange}
            />


            <button type="submit">Ajouter l'article</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Add;