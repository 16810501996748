import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './header.css';

const Header = () => {
  const [open, setOpen] = useState(false);

  const openMenu = () => {
    setOpen(!open);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  return (
    <header>
      <div className='blockMobile'>
        <div className='blockLogo'>
          <img src="/images/Accueil/logo.png" alt="Logo L'envol By Régine" />
        </div>
        <nav className={`${open ? 'menu' : 'menu mobile'}`} onClick={closeMenu}>
          <ul>
            <li>
              <Link className='Link' to="/" onClick={closeMenu}>
                Accueil
              </Link>
            </li>
            <li>
              <Link className='Link' to="/QuiSuisJe" onClick={closeMenu}>
                Qui suis je ?
              </Link>
            </li>
            <li>
              <Link className='Link' to="/MesServices" onClick={closeMenu}>
                Mes services
              </Link>
            </li>
            <li>
              <Link className='Link' to="/seances/1" onClick={closeMenu}>
                Séances & Tarifs
              </Link>
            </li>
            <li>
              <Link className='Link' to="/Actualites" onClick={closeMenu}>
                Actualités
              </Link>
            </li>
            <li>
              <Link className='Link' to="/Contact" onClick={closeMenu}>
                Contact
              </Link>
            </li>
          </ul>
        </nav>

        <div onClick={openMenu} className={`${open ? 'nav_burger actif' : 'nav_burger'}`}>
          <span className='burger_bar1'></span>
          <span className='burger_bar2'></span>
          <span className='burger_bar3'></span>
        </div>
      </div>
    </header>
  );
};

export default Header;
