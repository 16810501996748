import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Services.css";
import { Link } from 'react-router-dom';

const Services = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get('/app/getServices')
      .then((response) => {
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else {
          console.log("Les données n'ont pas été reçues : ", response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <section className='Services'>
      <h1>Mes services</h1>
      <div className="containerServices">
        {data.map((service) => (
          <div className="blockServices" key={service.id_Services}>
            <div>
              <h2>{service.titre_Services}</h2>
              <p>{service.texte_Services}</p>
              <div className="ServicesBtn">
                <Link to={`/seances/${service.id_Services}`}>
                  <button>Séances & Tarifs</button>
                </Link>
              </div>
            </div>
            <div className="ServicesImg">
              <img src={`/images/Services/${service.image_Services}`} alt="" />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
