import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { RxCross2 } from "react-icons/rx";

const Update = ({onClose}) => {

    const presentationId = 1;
    const [presentationData, setPresentationData] = useState({
        titre_Contact: "",
        texte_Contact: "",
        texte2_Contact: "",
        cordonnees_Contact: "",
    })

    const fetchData = async () => {
        try {
          const response = await axios.get(`/app/getPresentation/${presentationId}`);
    
          if (response.data.presentationData) {
            setPresentationData(response.data.presentationData);
            // console.log(response.data.presentationData);
          } else {
            console.log('Aucune donnée de présentation reçue');
          }

         
        } catch (error) {
          console.error('Erreur lors de la récupération des données de la présentation pour la modification', error);
        }
      };
    
      useEffect(() => {
        fetchData();
      }, []); // Appel une seule fois lors du montage du composant

      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!presentationId) {
            console.error('Id manquant')
            return;
        }

        try {
            const responsePresentation = await axios.put(
                `/app/updatePresentationContact/${presentationId}`,
                {
                    titre_Contact: presentationData.titre_Contact,
                    texte_Contact: presentationData.texte_Contact,
                    texte2_Contact: presentationData.texte2_Contact,
                    cordonnees_Contact: presentationData.cordonnees_Contact
                }
            );
            if (responsePresentation.data.presentationData) {
                console.log(responsePresentation.data.message);
                console.log('Updated Article Data:', responsePresentation.data.presentationData);
            } else {
                console.log("Aucune donnée d'article mise à jour reçue");
            }
            
            // window.location.reload()

        } catch (error) {
            console.error("Erreur lors de la mise à jour de l'article", error);
        }
      }

    return (
        <div className="modalForm">
            <div className="position-modalForm">
                <div className="contenu-modalForm">
                    <div className="closeButton" onClick={onClose}>
                        <RxCross2 className="iconeCross"  />
                    </div>
                    <h2>Modifier la presentation</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type='text'
                            value={presentationData.titre_Contact}
                            onChange={(e) =>
                                setPresentationData({
                                    ...presentationData,
                                    titre_Contact: e.target.value,
                                })
                            }
                        >
                        </input>
                        <textarea
                            type='text'
                            value={presentationData.texte_Contact}
                            onChange={(e) =>
                                setPresentationData({
                                    ...presentationData,
                                    texte_Contact: e.target.value,
                                })
                            }
                        >
                        </textarea>
                        <textarea
                            type='text'
                            value={presentationData.texte2_Contact}
                            onChange={(e) =>
                                setPresentationData({
                                    ...presentationData,
                                    texte2_Contact: e.target.value,
                                })
                            }
                        >
                        </textarea>
                        <input
                            type='text'
                            value={presentationData.cordonnees_Contact}
                            onChange={(e) =>
                                setPresentationData({
                                    ...presentationData,
                                    cordonnees_Contact: e.target.value,
                                })
                            }
                        >
                        </input>
                        <button type='submit'>Modifier la presentation</button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Update